import { useSelector, useDispatch } from "react-redux";
import { Typography } from "@mui/material";
import AppState from "../../store/AppState";
import {
  selectIsModalOpen,
  selectModalVariable,
} from "../../store/modal/ModalSelectors";
import { setModalState } from "../../store/modal/ModalActions";
import { useIntl } from "react-intl";
import CustomDialog from "../common/DialogContainer";
import ButtonComponent from "../common/ButtonComponent";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { addUpdateContentSlot } from "store/product-list/ProductListActions";
import { selectCurrentCatalogId } from "store/catalog/CatalogSelectors";
import { selectCurrentStoreId } from "store/store-list/StoreListSelectors";
import { selectCurrentCategory } from "store/category/CategorySelectors";

const useStyles = makeStyles(() =>
  createStyles({
    btnGroup: {
      textAlign: "end",
      margin: "20px 20px 10px",
      width: "100%",
    },
    divMessage: {
      marginTop: "5px",
    },
  }),
);

export const ConfirmAddContentSlotModalId = "ConfirmAddContentSlot";
const ConfirmAddContentSlotModal = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const classes = useStyles();
  const currentStoreId = useSelector(selectCurrentStoreId);
  const catalogId = useSelector(selectCurrentCatalogId);
  const categoryId = useSelector(selectCurrentCategory);
  const isModalOpen = useSelector((state: AppState) =>
    selectIsModalOpen(state, "ConfirmAddContentSlot"),
  );
  const targetPosition = useSelector((state: AppState) =>
    selectModalVariable(state, "ConfirmAddContentSlot", "targetPosition"),
  );

  const handleClose = () => {
    dispatch(setModalState("ConfirmAddContentSlot", false));
  };

  const handleAddContentSlot = () => {
    dispatch(
      addUpdateContentSlot({
        storeId: currentStoreId || "",
        catalogId,
        categoryId,
        position: targetPosition,
      }),
    );
  };

  const confirmAddContentSlotModalTitle = () => {
    return (
      <Typography variant="inherit">
        {intl.formatMessage({
          id: "confirmAddContentSlot.header",
          defaultMessage: "Add Content Slot",
        })}
      </Typography>
    );
  };

  const confirmAddContentSlotModalContent = () => {
    return (
      <div className={classes.divMessage}>
        <Typography variant="subtitle1">
          {intl.formatMessage({
            id: "confirmAddContentSlot.instruction",
            defaultMessage: "Are you sure you want to add the content slot?",
          })}
        </Typography>
      </div>
    );
  };

  const dialogActions = () => {
    return (
      <div className={classes.btnGroup}>
        <ButtonComponent
          color="secondaryButtonColorCTABlue"
          variant="outlined"
          onClick={handleClose}
        >
          {intl.formatMessage({
            id: "general.cancel",
            defaultMessage: "Cancel",
          })}
        </ButtonComponent>
        <ButtonComponent
          color="primary"
          variant="contained"
          onClick={() => {
            handleAddContentSlot();
            handleClose();
          }}
        >
          {intl.formatMessage({
            id: "general.Proceed",
            defaultMessage: "Proceed",
          })}
        </ButtonComponent>
      </div>
    );
  };

  return (
    <CustomDialog
      open={isModalOpen}
      onClose={() => handleClose()}
      title={confirmAddContentSlotModalTitle()}
      actions={dialogActions()}
      maxWidth={"md"}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.1)",
          },
        },
      }}
    >
      {confirmAddContentSlotModalContent()}
    </CustomDialog>
  );
};

export default ConfirmAddContentSlotModal;
