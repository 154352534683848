export interface PrecachingCrawlerConfig {
  accountId: string;
  schedule: {
    enabled: boolean;
    cronExpression: string;
    timezone: string;
  };
  dataTypes: string[];
}

export interface PrecachingCrawlerState {
  config: PrecachingCrawlerConfig | null;
  isFetching: boolean;
  isUpdating: boolean;
  error: string | null;
}

export const FETCH_CONFIG_REQUEST = "FETCH_CONFIG_REQUEST";
export const FETCH_CONFIG_SUCCESS = "FETCH_CONFIG_SUCCESS";
export const FETCH_CONFIG_FAILURE = "FETCH_CONFIG_FAILURE";

export const UPDATE_CONFIG_REQUEST = "UPDATE_CONFIG_REQUEST";
export const UPDATE_CONFIG_SUCCESS = "UPDATE_CONFIG_SUCCESS";
export const UPDATE_CONFIG_FAILURE = "UPDATE_CONFIG_FAILURE";

export const CREATE_PRECACHING_JOB_REQUEST = "CREATE_PRECACHING_JOB_REQUEST";
export const CREATE_PRECACHING_JOB_SUCCESS = "CREATE_PRECACHING_JOB_SUCCESS";
export const CREATE_PRECACHING_JOB_FAILURE = "CREATE_PRECACHING_JOB_FAILURE";

export const GET_PRECACHING_JOB_REQUEST = "GET_PRECACHING_JOB_REQUEST";
export const GET_PRECACHING_JOB_SUCCESS = "GET_PRECACHING_JOB_SUCCESS";
export const GET_PRECACHING_JOB_FAILURE = "GET_PRECACHING_JOB_FAILURE";

export const GET_ALL_PRECACHING_JOBS_REQUEST =
  "GET_ALL_PRECACHING_JOBS_REQUEST";
export const GET_ALL_PRECACHING_JOBS_SUCCESS =
  "GET_ALL_PRECACHING_JOBS_SUCCESS";
export const GET_ALL_PRECACHING_JOBS_FAILURE =
  "GET_ALL_PRECACHING_JOBS_FAILURE";

export const CANCEL_PRECACHING_JOB_REQUEST = "CANCEL_PRECACHING_JOB_REQUEST";
export const CANCEL_PRECACHING_JOB_SUCCESS = "CANCEL_PRECACHING_JOB_SUCCESS";
export const CANCEL_PRECACHING_JOB_FAILURE = "CANCEL_PRECACHING_JOB_FAILURE";

export interface PrecachingJob {
  accountId: string;
  jobId: string;
  status: string;
  createdAt: string;
  updatedAt: string;
}

export type PrecachingCrawlerActionTypes =
  | { type: typeof FETCH_CONFIG_REQUEST }
  | { type: typeof FETCH_CONFIG_SUCCESS; payload: PrecachingCrawlerConfig }
  | { type: typeof FETCH_CONFIG_FAILURE; payload: string }
  | { type: typeof UPDATE_CONFIG_REQUEST; payload: PrecachingCrawlerConfig }
  | { type: typeof UPDATE_CONFIG_SUCCESS; payload: PrecachingCrawlerConfig }
  | { type: typeof UPDATE_CONFIG_FAILURE; payload: string }
  | { type: typeof CREATE_PRECACHING_JOB_REQUEST }
  | { type: typeof CREATE_PRECACHING_JOB_SUCCESS; payload: PrecachingJob }
  | { type: typeof CREATE_PRECACHING_JOB_FAILURE; payload: string }
  | { type: typeof GET_PRECACHING_JOB_REQUEST }
  | { type: typeof GET_PRECACHING_JOB_SUCCESS; payload: PrecachingJob }
  | { type: typeof GET_PRECACHING_JOB_FAILURE; payload: string }
  | { type: typeof GET_ALL_PRECACHING_JOBS_REQUEST }
  | { type: typeof GET_ALL_PRECACHING_JOBS_SUCCESS; payload: PrecachingJob[] }
  | { type: typeof GET_ALL_PRECACHING_JOBS_FAILURE; payload: string }
  | { type: typeof CANCEL_PRECACHING_JOB_REQUEST }
  | { type: typeof CANCEL_PRECACHING_JOB_SUCCESS }
  | { type: typeof CANCEL_PRECACHING_JOB_FAILURE; payload: string };
