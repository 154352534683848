import { createSelector } from "reselect";
import AppState from "../AppState";
import {
  BusinessRulesListDetails,
  ProductId,
  ProductSequenceData,
} from "./ProductListTypes";
import { SEQUENCE_MODE_TYPE } from "../../utils/Constants";
import { isProductPublishedSFCC } from "../../utils/ProductUtil";
import {
  selectIsFetchedBySearch,
  selectProductColors,
  selectProductIdsBySearch,
  selectProducts,
} from "../product/ProductSelectors";
export const selectProductListsFetched = (
  state: AppState,
): ProductSequenceData[] => {
  return state?.productList ? state.productList?.productSequences : [];
};
export const selectProductListEditSequence = (
  state: AppState,
): ProductSequenceData[] => {
  return state.productList ? state.productList.editSequenceList : [];
};
export const selectPublishedProductList = createSelector(
  [selectProductListsFetched, selectProducts],
  (productList, productById) => {
    if (productList) {
      return productList.filter((categoryToProduct) =>
        isProductPublishedSFCC(productById[categoryToProduct.productId]),
      );
    }
    return [];
  },
);

export const selectEditSequenceList = (
  state: AppState,
): ProductSequenceData[] => {
  return state.productList &&
    state.productList.editSequenceList &&
    Array.isArray(state.productList.editSequenceList) &&
    state.productList.editSequenceList.length
    ? state.productList.editSequenceList?.map((sequenceList, sequenceIndex) => {
        return {
          ...sequenceList,
          sequence: sequenceList.sequence,
          isInitialPositionAvailable: sequenceList.sequence ? true : false,
        };
      })
    : [];
};

export const selectPublishedSequenceList = createSelector(
  [selectEditSequenceList, selectProducts],
  (productList, productById) => {
    if (productList) {
      return productList.filter((categoryToProduct) =>
        isProductPublishedSFCC(productById[categoryToProduct.productId]),
      );
    }
    return [];
  },
);
export const selectPublishedSequenceListIds = createSelector(
  [
    selectEditSequenceList,
    selectProductIdsBySearch,
    selectIsFetchedBySearch,
    selectProducts,
  ],
  (productList, productIdsBySearch, isFetchedBySearch, productById) => {
    if (productList.length > 0 && !isFetchedBySearch) {
      return productList
        .filter((categoryToProduct) =>
          isProductPublishedSFCC(productById[categoryToProduct.productId]),
        )
        .map((product) => product.productId);
    } else if (productIdsBySearch.length > 0 && isFetchedBySearch) {
      return productIdsBySearch;
    }
    return [];
  },
);

export const selectIsProductListsFetched = (state: AppState): boolean => {
  return state.productList ? state.productList.isFetched : false;
};

export const selectIsProductListFetching = (state: AppState): boolean => {
  return state.productList ? state.productList.isFetching : false;
};

export const shouldUpdateEditSequenceList = createSelector(
  [selectEditSequenceList, selectProductListsFetched],
  (products, oldProductsState) => {
    // const filteredProducts = products.filter((categoryToProduct, productIndex) =>
    //   isProductPublishedSFCC(categoryToProduct.product)
    // );
    const filteredProducts = products;
    const oldProductSequenceState = oldProductsState;
    let isSaveButtonEnabled =
      Array.isArray(oldProductSequenceState) &&
      oldProductSequenceState.some(
        (product, prdIndex) =>
          product.productId !== filteredProducts?.[prdIndex]?.productId,
      );
    let prevSequence = 0;
    let shouldUpdate = false;
    for (let index = 0; index < filteredProducts.length; index++) {
      if (filteredProducts?.[index]?.sequence && isSaveButtonEnabled) {
        shouldUpdate = true;
        break;
      }
      prevSequence = filteredProducts?.[index]?.sequence || prevSequence;
    }
    return shouldUpdate;
  },
);

export const selectProductSequenceMode = (state: AppState) => {
  return state.productList.sequenceMode === 2;
};

export const selectHasNullSequences = createSelector(
  [selectEditSequenceList, selectProducts],
  (products, productById) => {
    const filteredProducts = products.filter((categoryToProduct) =>
      isProductPublishedSFCC(productById[categoryToProduct.productId]),
    );
    let hasNullSequence = false;
    for (let index = 0; index < filteredProducts.length; index++) {
      if (!filteredProducts?.[index]?.sequence) {
        hasNullSequence = true;
        break;
      }
    }
    return hasNullSequence;
  },
);

export const selectProductListSequenceMode = (state: AppState): number => {
  return state.productList
    ? state.productList.sequenceMode
    : SEQUENCE_MODE_TYPE.VIEW;
};

export const selectProductsWithInvalidSequences = (
  state: AppState,
): string[] => {
  const invalidSequenceProducts: string[] = [];
  if (state.productList) {
    state.productList.productSequences.forEach((product) => {
      if (!product.sequence) invalidSequenceProducts.push(product.productId);
    });
  }
  return invalidSequenceProducts;
};

export const selectMaxSequenceInList = createSelector(
  [selectProductListsFetched],
  (productList) => {
    let maxSequence = 1;
    if (productList) {
      const seqArray = productList.map((product) => product.sequence);
      maxSequence = Math.max(...seqArray.filter((x) => x !== undefined));
    }
    return maxSequence;
  },
);

export const selectNumberOfPinnedProducts = (state: AppState): number => {
  return state.productList && state.productList.pinnedProducts
    ? state.productList.pinnedProducts.numberOfPinnedProducts
    : 0;
};

export const selectPinnedProducts = (state: AppState): string[] => {
  return state.productList && state.productList.pinnedProducts
    ? state.productList.pinnedProducts.pinnedProductIds
    : [];
};

export const shouldUpdateNewPinnedProducts = createSelector(
  [
    selectNumberOfPinnedProducts,
    selectPinnedProducts,
    selectPublishedSequenceList,
    selectIsFetchedBySearch,
  ],
  (
    numberOfPinnedProducts,
    pinnedProducts,
    editSequenceList,
    isFetchedBySearch,
  ) => {
    const newPinnedProducts = editSequenceList
      .map((product) => product.productId)
      .slice(0, numberOfPinnedProducts);
    if (isFetchedBySearch) {
      return false;
    }
    let shouldUpdate = false;
    if (pinnedProducts.length !== numberOfPinnedProducts) {
      shouldUpdate = true;
    } else {
      for (let i = 0; i < newPinnedProducts.length; i++) {
        if (newPinnedProducts[i] !== pinnedProducts[i]) {
          shouldUpdate = true;
          break;
        }
      }
    }
    return shouldUpdate;
  },
);

export const selectIsSequenceUpdated = (state: AppState): boolean => {
  return state.productList && state.productList.isSequenceUpdated;
};

export const selectIsSequenceLoading = (state: AppState): boolean => {
  return state.productList && state.productList.isSequenceLoading;
};

export const selectIsSequenceSaving = (state: AppState): boolean => {
  return state.productList?.isSequenceSaving ?? false;
};

export const selectIsPinnedProductsMode = (state: AppState): boolean => {
  return state.productList && state.productList.isPinnedProductsMode;
};

export const selectSortingRule = (state: AppState): string | null => {
  return state.productList && state.productList.sortingRule;
};

export const getProductSequenceRequestId = (state: AppState): string | null => {
  return state.productList && state.productList.requestId;
};

export const selectSortingRulesList = (
  state: AppState,
): BusinessRulesListDetails[] => {
  return state.productList.sortingRulesList.length
    ? state.productList.sortingRulesList
    : [];
};
export const selectAppliedRulesList = (
  state: AppState,
): BusinessRulesListDetails[] => {
  return state?.businessRulesList?.businessRulesList ?? [];
};

export const selectSortingListLoadedState = (state: AppState): boolean => {
  return state.productList && state.productList.isSortingRulesListLoaded;
};

export const selectIsProductIdsFetched = (state: AppState): boolean => {
  return state.productList && state.productList.isProductIdsFetched;
};

export const selectIsFetchingProductIds = (state: AppState): boolean => {
  return state.productList && state.productList.isFetchingProductIds;
};

export const selectProductIdsCount = (state: AppState): number => {
  return state.productList && state.productList.productIds.length;
};

export const selectGetProductIds = (state: AppState): ProductId[] => {
  return (state.productList && state.productList.productIds) || [];
};

export const selectProductIds = selectGetProductIds;

export const isRefreshingAllProducts = (state: AppState) => {
  return state.productList && state.productList.isRefreshingAllProducts;
};
export const isFetchingSortedProducts = (state: AppState): boolean => {
  return state.productList && state.productList.isFetchingSortedProducts;
};

export const selectIsUpdateProductSequenceInvoked = (state: AppState) => {
  return state.productList && state.productList.isUpdateFunctionInvoked;
};

export const isAddingRemovingProductIdsList = (state: AppState) => {
  return state.productList && state.productList.addingRemovingProductIdsList;
};

export const selectIsProductSequenceUpdateFailure = (state: AppState) => {
  return state.productList && state.productList.isUpdateProductSequenceError;
};

export const selectWorkingProductSequence = (state: AppState) => {
  return state.productList?.updatedStateProductIds;
};

export const selectNext = (state: AppState) => {
  return state.productList?.productIdsData?.next;
};

export const selectCount = (state: AppState) => {
  return state.productList?.productIdsData?.count;
};

export const selectTotal = (state: AppState) => {
  return state.productList?.productIdsData?.total;
};

export const selectWatchedProductsList = (state: AppState) => {
  return state.productList && state.productList.watchedProductList;
};

export const selectProgressLoadingPercentage = createSelector(
  [selectTotal, selectProductIds, selectProducts, selectProductColors],
  (total, productIds, productById, colorById) => {
    if (total === undefined || total === 0) {
      return 0;
    }

    const totalProductDetails = productIds.filter(
      (i) => productById[i] !== undefined,
    ).length;
    const totalProductColors = productIds.filter(
      (i) => !productById[i]?.hasColors || colorById[i] !== undefined,
    ).length;

    return Math.ceil(
      (100 * (totalProductDetails + totalProductColors)) / (total * 2),
    );
  },
);

export const selectIsSequenceResponseFetched = (state: AppState): boolean => {
  return (
    state.productList &&
    state.productList.productSequenceList?.response?.statusCode === 201
  );
};

export const selectIsSequenceUpdatedRequest = (state: AppState): boolean => {
  return state.productList && state.productList.isSquenceUpdateRequest;
};

export const selectIsSequenceRequestedByBoostBury = (
  state: AppState,
): boolean => {
  return state.productList && state.productList.isBoostBurySequenceRequested;
};

export const selectLockedProductInCurrentCategory = (
  state: AppState,
  categoryId: string,
  catalogId: string,
  productId: string,
  position?: number,
) => {
  return (
    state.productList &&
    state.productList.locksInCategory?.find(
      (lock) =>
        lock.categoryId === categoryId &&
        lock.catalogId === catalogId &&
        lock.productId === productId &&
        lock.position === position,
    )
  );
};

export const selectSavedLockedProducts = (state: AppState) => {
  return state.productList && state.productList.savedLockedProductsInCategory;
};

export const selectSavedUnlockedProducts = (state: AppState) => {
  return state.productList && state.productList.savedUnlockedProductsInCategory;
};

export const selectLockedProducts = (
  state: AppState,
  categoryId: string,
  catalogId: string,
) => {
  return (
    state.productList &&
    state.productList.locksInCategory?.filter(
      (lock) => lock.categoryId === categoryId && lock.catalogId === catalogId,
    )
  );
};

export const selectRemovedLockedProductsInCategory = (
  state: AppState,
  categoryId: string,
  catalogId: string,
) => {
  return (
    state.productList &&
    state.productList.locksRemoveInCategory?.filter(
      (lock) => lock.categoryId === categoryId && lock.catalogId === catalogId,
    )
  );
};

export const selectContentSlots = (state: AppState) => {
  return state.productList && state.productList.contentSlots;
};

export const selectStoredPinnedProductIds = (state: AppState) => {
  return state.productList && state.productList.storedPinnedProductIds;
};

export const selectPinnedProductIds = (state: AppState) => {
  return state.productList && state.productList.pinnedProductIds;
};

export const selectStoredUnpinnedProductIds = (state: AppState) => {
  return state.productList && state.productList.storedUnpinnedProductIds;
};

export const selectIsContentSlotLoading = (state: AppState): boolean => {
  return state.productList?.isContentSlotLoading ?? false;
};
