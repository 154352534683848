import { call, put, select, takeLatest } from "typed-redux-saga/macro";
import {
  BestsellersProductAction,
  FETCH_BESTSELLERS_PRODUCTS,
  FETCH_FREQUENT_BOUGHT_TOGETHER_PRODUCTS,
  FETCH_PRODUCTS_DETAILS_FOR_FREQ_PRODUCTS,
  FETCH_TRENDING_PRODUCTS,
  FreqBoughtTogetherAction,
  GET_BESTSELLERS_PROD_LIST,
  GET_FREQ_BOUGHT_TOGETHER_LIST,
  GET_TRENDING_PROD_LIST,
  LOAD_PRODUCT_DETAILS_FOR_FREQ_PRODUCTS,
  loadProductsForFreqProductsAction,
  TrendingProductAction,
} from "./RecommendationType";
import { acquireEndpoint } from "../../utils/SmartMerchandiserAPI";
import { callApi } from "../../utils/SagaUtils";
import { getData } from "../../services/ApiService";
import {
  selectCurrentLocale,
  selectCurrentStoreId,
} from "store/store-list/StoreListSelectors";
import { selectCurrentCatalogId } from "store/catalog/CatalogSelectors";

function* fetchFreqBoughtTogetherList(action: FreqBoughtTogetherAction) {
  try {
    yield* put({ type: FETCH_FREQUENT_BOUGHT_TOGETHER_PRODUCTS.REQUEST });
    const actionType = FETCH_FREQUENT_BOUGHT_TOGETHER_PRODUCTS;
    const constName = Object.keys({
      FETCH_FREQUENT_BOUGHT_TOGETHER_PRODUCTS,
    })[0].toString();
    const { productId, limit, period } = action.payload;
    const storeId = yield* select(selectCurrentStoreId);
    const headersObj = {
      "x-store-id": storeId,
    };
    const endpoint = acquireEndpoint(constName, productId, period, limit);
    yield* call(
      callApi,
      actionType,
      getData,
      action.payload,
      endpoint,
      headersObj,
    );
  } catch (e: any) {
    console.error(e);
    yield* put({
      type: FETCH_FREQUENT_BOUGHT_TOGETHER_PRODUCTS.FAILURE,
      message: e.message,
    });
  }
}

function* loadProductListForFeqProductsModal(
  action: loadProductsForFreqProductsAction,
) {
  try {
    const { productIds } = action.payload;
    const localeCode = yield* select(selectCurrentLocale);
    const storeId = yield* select(selectCurrentStoreId);
    const catalogId = yield* select(selectCurrentCatalogId);
    const actionType = FETCH_PRODUCTS_DETAILS_FOR_FREQ_PRODUCTS;
    const constName = Object.keys({
      FETCH_PRODUCTS_DETAILS_FOR_FREQ_PRODUCTS,
    })[0].toString();

    let commaSeperatedProductIds: string = "";

    if (productIds && Array.isArray(productIds) && productIds.length) {
      commaSeperatedProductIds = String(
        productIds.length > 1
          ? productIds.join(",").toString()
          : productIds.length && productIds[0],
      );
    }

    const endpoint = acquireEndpoint(constName, commaSeperatedProductIds);
    const headersObj = {
      "x-locale-code": localeCode || "default",
      "x-currency-code": "USD",
      "x-store-id": storeId,
      "x-catalog-id": catalogId,
    };

    yield* call(
      callApi,
      actionType,
      getData,
      action.payload,
      endpoint,
      headersObj,
    );
  } catch (e: any) {
    yield* put({
      type: FETCH_PRODUCTS_DETAILS_FOR_FREQ_PRODUCTS.FAILURE,
      message: e.message,
    });
  }
}

function* fetchBestSellersList(action: BestsellersProductAction) {
  try {
    yield* put({ type: FETCH_BESTSELLERS_PRODUCTS.REQUEST });
    const actionType = FETCH_BESTSELLERS_PRODUCTS;
    const constName = Object.keys({
      FETCH_BESTSELLERS_PRODUCTS,
    })[0].toString();
    const { limit, period } = action.payload;
    const storeId = yield* select(selectCurrentStoreId);
    const headersObj = {
      "x-store-id": storeId,
    };
    const endpoint = acquireEndpoint(constName, period, limit);
    yield* call(
      callApi,
      actionType,
      getData,
      action.payload,
      endpoint,
      headersObj,
    );
  } catch (e: any) {
    console.error(e);
    yield* put({
      type: FETCH_FREQUENT_BOUGHT_TOGETHER_PRODUCTS.FAILURE,
      message: e.message,
    });
  }
}

function* fetchTrendingProductsList(action: TrendingProductAction) {
  try {
    yield* put({ type: FETCH_TRENDING_PRODUCTS.REQUEST });
    const actionType = FETCH_TRENDING_PRODUCTS;
    const constName = Object.keys({
      FETCH_TRENDING_PRODUCTS,
    })[0].toString();
    const { limit, period } = action.payload;
    const storeId = yield* select(selectCurrentStoreId);
    const headersObj = {
      "x-store-id": storeId,
    };
    const endpoint = acquireEndpoint(constName, period, limit);
    yield* call(
      callApi,
      actionType,
      getData,
      action.payload,
      endpoint,
      headersObj,
    );
  } catch (e: any) {
    console.error(e);
    yield* put({
      type: FETCH_TRENDING_PRODUCTS.FAILURE,
      message: e.message,
    });
  }
}

export function* watchBestsellersProductList() {
  yield* takeLatest(GET_BESTSELLERS_PROD_LIST, fetchBestSellersList);
}

export function* watchTrendingProductList() {
  yield* takeLatest(GET_TRENDING_PROD_LIST, fetchTrendingProductsList);
}

export function* watchfetchFreqBoughtTogetherList() {
  yield* takeLatest(GET_FREQ_BOUGHT_TOGETHER_LIST, fetchFreqBoughtTogetherList);
}

export function* watchfetchProductDetailsForRecommendation() {
  yield* takeLatest(
    LOAD_PRODUCT_DETAILS_FOR_FREQ_PRODUCTS,
    loadProductListForFeqProductsModal,
  );
}
