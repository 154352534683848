import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { CircularProgress, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import AppState from "../../store/AppState";
import { selectIsModalOpen } from "../../store/modal/ModalSelectors";
import { setModalState } from "../../store/modal/ModalActions";
import {
  componentClasses,
  StyledBox,
  StyledDiv,
} from "./GlobalRecommendationsStyles";
import { selectCurrentCategory } from "../../store/category/CategorySelectors";
import CustomDialog from "../common/DialogContainer";
import ButtonComponent from "../common/ButtonComponent";
import { makeStyles, createStyles, Theme } from "@material-ui/core";
import { loadProductsFromCategory } from "store/product-list/ProductListActions";
import { resetAllProducAnalyticsView } from "store/product/ProductActions";
import { selectCurrentCatalogId } from "store/catalog/CatalogSelectors";
import {
  fetchBestsellersProductsRequestAction,
  fetchTrendingProductsRequestAction,
  loadProductsDetailsForFreqProducts,
  resetGlobalRecommendationList,
} from "store/recommendations/RecommendationAction";
import {
  selectBestSellersProdIds,
  selectTrendingProdIds,
  selectGlobalRecommendationsProducts,
  selectIsGlobalRecommendationFetched,
  selectIsProductDetailsFetched,
} from "store/recommendations/RecommendationSelector";
import {
  selectCurrentLocale,
  selectCurrentStoreId,
  selectStoreListIds,
} from "store/store-list/StoreListSelectors";
import GlobalRecommendationProductItem from "./GlobalRecommendationProductItem";

export const BestSellersModalId = "BestSellersModal";
export const TrendingProductsModalId = "TrendingProductsModal";

interface GlobalRecommendationsModalProps {
  modalId: string;
  title: string;
  productType: "bestsellers" | "trending";
  noProductsMessageId: string;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    rightSection: {
      position: "sticky",
      top: 0,
      marginLeft: "auto",
      minWidth: "150px",
      width: "auto",
      height: "74vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(1),
      [theme.breakpoints.down("md")]: {
        minWidth: "200px",
      },
      overflowY: "auto",
      right: 0,
    },
    divActionBtn: {
      display: "flex",
      gap: 17,
      alignItems: "flex-end",
      justifyContent: "flex-end",
      width: "100%",
      margin: "20px",
    },
  }),
);

const GlobalRecommendationsModal: React.FC<GlobalRecommendationsModalProps> = ({
  modalId,
  title,
  productType,
  noProductsMessageId,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const classes = useStyle();
  const [isAnalyticsDisplayed, setIsAnalyticsDisplayed] =
    useState<boolean>(false);
  const bestsellerProductIds = useSelector(selectBestSellersProdIds);
  const trendingProductIds = useSelector(selectTrendingProdIds);
  const productIds =
    productType === "bestsellers" ? bestsellerProductIds : trendingProductIds;

  const categoryId = useSelector(selectCurrentCategory);
  const catalogId = useSelector(selectCurrentCatalogId);
  const localeCode = useSelector(selectCurrentLocale);
  const storeListIds = useSelector(selectStoreListIds);
  const storeId = useSelector(selectCurrentStoreId);

  const isProductDetailsFetched = useSelector(selectIsProductDetailsFetched);
  const isFetchingProductIds = useSelector(selectIsGlobalRecommendationFetched);
  const productDetails = useSelector(selectGlobalRecommendationsProducts);

  const isModalOpen = useSelector((state: AppState) =>
    selectIsModalOpen(state, modalId),
  );

  const handleClose = () => {
    dispatch(setModalState(modalId, false));
    dispatch(resetGlobalRecommendationList());
    dispatch(resetAllProducAnalyticsView());
    setIsAnalyticsDisplayed(false);
  };

  useEffect(() => {
    if (productIds.length > 0 && storeId) {
      let payload: Parameters<typeof loadProductsFromCategory>[0] = {
        categoryId,
        catalogId,
        localeCode,
        storeId,
        productIds,
        storeListIds,
        isRefreshingAllProducts: true,
      };
      dispatch(loadProductsDetailsForFreqProducts(payload));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, productIds, storeId]);

  useEffect(() => {
    if (isModalOpen) {
      const payload = {
        period: 30,
        limit: 8,
      };

      if (productType === "bestsellers") {
        dispatch(fetchBestsellersProductsRequestAction(payload));
      } else {
        dispatch(fetchTrendingProductsRequestAction(payload));
      }
    }
  }, [dispatch, isModalOpen, productType]);

  const rightSection = () => (
    <div className={classes.divActionBtn}>
      <ButtonComponent
        color="primary"
        variant="contained"
        onClick={handleClose}
      >
        {intl.formatMessage({
          id: "general.close",
          defaultMessage: "Close",
        })}
      </ButtonComponent>
    </div>
  );

  const modalContent = () => (
    <StyledDiv className={componentClasses.paper}>
      <StyledDiv>
        <StyledDiv
          className={componentClasses.selectProductToSubstitute}
        ></StyledDiv>
        <StyledDiv className={componentClasses.mainContent}>
          <StyledBox className={componentClasses.mainSection}>
            {isFetchingProductIds || isProductDetailsFetched ? (
              <StyledDiv className={componentClasses.progress}>
                <CircularProgress style={{ margin: "auto" }} size="40px" />
              </StyledDiv>
            ) : productDetails && productDetails.length > 0 ? (
              productDetails?.map((product, productIndex) => (
                <GlobalRecommendationProductItem
                  product={product}
                  code={product?.code ?? ""}
                  price={product?.highPrice}
                  productId={product?.productId ?? ""}
                  isColumn={true}
                  index={productIndex}
                  key={productIndex.toString()}
                  isAnalyticsDisplayed={isAnalyticsDisplayed}
                  setIsAnalyticsDisplayed={setIsAnalyticsDisplayed}
                />
              ))
            ) : (
              <StyledBox className={componentClasses.noSimilarProducts}>
                <Typography variant="h5" style={{ textAlign: "center" }}>
                  {intl.formatMessage({
                    id: noProductsMessageId,
                    defaultMessage: `No ${productType} products found`,
                  })}
                </Typography>
              </StyledBox>
            )}
          </StyledBox>
        </StyledDiv>
      </StyledDiv>
    </StyledDiv>
  );

  return (
    <CustomDialog
      open={isModalOpen}
      onClose={handleClose}
      title={<Typography variant="inherit">{title}</Typography>}
      fullWidth
      maxWidth={"lg"}
      onClick={(e) => e.preventDefault()}
      actions={rightSection()}
    >
      {modalContent()}
    </CustomDialog>
  );
};

export default React.memo(GlobalRecommendationsModal);
