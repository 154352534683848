import AppState from "../AppState";

export const selectPrecachingCrawlerConfig = (state: AppState) =>
  state.precachingCrawler.config;
export const selectIsFetchingConfig = (state: AppState) =>
  state.precachingCrawler.isFetching;
export const selectIsUpdatingConfig = (state: AppState) =>
  state.precachingCrawler.isUpdating;
export const selectPrecachingCrawlerError = (state: AppState) =>
  state.precachingCrawler.error;
