import {
  FETCH_CATALOGS,
  FETCH_STORES_SUCCESS,
  LoadCatalogsResponseAction,
  LoadStoresResponseAction,
  FETCH_ALL_TOP_CATEGORIES,
  LoadAllTopCategoriesResponseAction,
  FETCH_ALL_CHILD_CATEGORIES,
  LoadAllChildCategoriesResponseAction,
  ADD_PRODUCTS_TO_CATEGORIES,
  RESET_PRODUCT_ADDED_STATE,
  POST_PRODUCTS_TO_CATEGORIES,
} from "./AddProductCategoriesTypes";
import { decodeNodeId, encodeNodeId } from "./AddProductCategoriesUtils";

export interface TreeData {
  data: any[];
  isProductAdded: boolean;
  areAllProductsAdded: boolean;
  isProgressing: boolean;
}

const initialState: TreeData = {
  isProductAdded: false,
  data: [],
  areAllProductsAdded: false,
  isProgressing: false,
};

export const AddProductCategoriesReducer = (
  state = initialState,
  action:
    | LoadStoresResponseAction
    | LoadCatalogsResponseAction
    | LoadAllTopCategoriesResponseAction,
): any => {
  switch (action.type) {
    case FETCH_STORES_SUCCESS:
      const storeListAction = action as LoadStoresResponseAction;
      const store = storeListAction.payload.results.map((e) => {
        return {
          id: e.storeId,
          name: e.name || e.code,
          nodeId: e.storeId,
          children: null,
        };
      });
      return {
        ...state,
        data: store,
      };
    case FETCH_CATALOGS.SUCCESS: {
      const fetchAction = action as LoadCatalogsResponseAction;
      const store = state.data.find(
        (st) => st.id === fetchAction.payload.storeId,
      );
      const results = fetchAction.payload.results.map((catalog) => ({
        id: catalog.catalogId,
        name: catalog.name,
        children: null,
        nodeId: encodeNodeId([store.nodeId, catalog.catalogId]),
      }));
      store.children = results;
      return {
        ...state,
        data: state.data.map((st) =>
          st.id !== store.id
            ? st
            : {
                ...store,
              },
        ),
      };
    }
    case FETCH_ALL_TOP_CATEGORIES.SUCCESS: {
      const {
        payload: { storeId, results, catalogId },
      } = action as LoadAllTopCategoriesResponseAction;
      const store = state.data.find((st) => st.id === storeId);
      const catalog = store.children.find((cat) => cat.id === catalogId);
      const data = results.map((category) => ({
        ...category,
        id: category.categoryId,
        nodeId: encodeNodeId([
          ...decodeNodeId(catalog.nodeId),
          category.categoryId,
        ]),
        children: null,
      }));

      catalog.children = data;

      return {
        ...state,
        data: state.data.map((st) =>
          st.id !== store.id
            ? st
            : {
                ...store,
              },
        ),
      };
    }
    case FETCH_ALL_CHILD_CATEGORIES.SUCCESS: {
      const fetchAction = action as LoadAllChildCategoriesResponseAction;
      const {
        payload: { storeId, catalogId, nodeId, results },
      } = fetchAction;
      const isTopCategoryExists = results?.some((e) => e.parentId === "root");
      const categoryId: string = !isTopCategoryExists
        ? results?.[0]?.categoryId
        : "";
      const fetchActionResults = isTopCategoryExists
        ? results?.filter((e) => e.parentId !== "root")
        : results?.some((e) => categoryId === e.parentId)
          ? results?.filter((e) => categoryId === e.parentId)
          : results;

      const store = state.data.find((st) => st.id === storeId);
      const catalog = store.children.find((cat) => cat.id === catalogId);
      let category = catalog.children;
      const parts = decodeNodeId(nodeId);
      for (let i = 2; i < parts.length; i++) {
        category = category.find((c) => c.id === parts[i]);
        if (i !== parts.length - 1) {
          category = category.children;
        }
      }

      category.children = fetchActionResults.map((c) => ({
        ...c,
        id: c.categoryId,
        nodeId: encodeNodeId([...decodeNodeId(category.nodeId), c.categoryId]),
        children: null,
      }));

      return {
        ...state,
        data: state.data.map((st) =>
          st.id !== store.id
            ? st
            : {
                ...store,
              },
        ),
      };
    }
    case ADD_PRODUCTS_TO_CATEGORIES.REQUEST: {
      return {
        ...state,
        areAllProductsAdded: false,
      };
    }
    case ADD_PRODUCTS_TO_CATEGORIES.SUCCESS: {
      return {
        ...state,
        isProductAdded: true,
      };
    }
    case ADD_PRODUCTS_TO_CATEGORIES.FAILURE: {
      return {
        ...state,
        isProductAdded: true,
        isProgressStopped: true,
        isProgressing: false,
      };
    }
    case RESET_PRODUCT_ADDED_STATE: {
      return {
        ...state,
        isProductAdded: false,
        areAllProductsAdded: false,
      };
    }
    case POST_PRODUCTS_TO_CATEGORIES: {
      return {
        ...state,
        isProgressing: true,
      };
    }
    case ADD_PRODUCTS_TO_CATEGORIES.COMPLETED: {
      return {
        ...state,
        areAllProductsAdded: true,
        isProgressing: false,
      };
    }
    default:
      return state;
  }
};
