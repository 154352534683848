import React from "react";
import GlobalRecommendationsModal, {
  BestSellersModalId,
} from "./GlobalRecommendationsModal";

const BestSellersModal = () => {
  return (
    <GlobalRecommendationsModal
      modalId={BestSellersModalId}
      title="Bestsellers"
      productType="bestsellers"
      noProductsMessageId="bestsellers.noProductsFoundText"
    />
  );
};

export default React.memo(BestSellersModal);
