import AppState from "../AppState";

export const selectFreqBoughtProducts = (state: AppState) => {
  return state?.recommendationProducts?.freqBoughtTogetherIds ?? [];
};

export const selectIsFreqProductsFetched = (state: AppState) => {
  return state?.recommendationProducts.isFetchingFreqProductsIds;
};

export const selectIsGlobalRecommendationFetched = (state: AppState) => {
  return state.recommendationProducts.isFetchingGlobalRecommendations;
};

export const selectBestSellersProdIds = (state: AppState) => {
  return state?.recommendationProducts.bestsellersProductIds ?? [];
};

export const selectTrendingProdIds = (state: AppState) => {
  return state?.recommendationProducts.trendingProductIds ?? [];
};

export const selectFreqProductsDetails = (state: AppState) => {
  return state?.recommendationProducts?.productsListDeatils ?? [];
};

export const selectFreqProductFetched = (
  state: AppState,
  productId: string,
) => {
  const productsList = state.recommendationProducts?.productsListDeatils || [];
  return (
    productsList.find((product) => product.productId === productId) || null
  );
};

export const selectIsProductDetailsFetched = (state: AppState) => {
  return state?.recommendationProducts?.isProductDetailsFetched;
};

export const selectGlobalRecommendationsProducts = (state: AppState) => {
  return state?.recommendationProducts?.productsListDeatils ?? [];
};
