import { call, put, takeLatest } from "typed-redux-saga/macro";
import { getData, putData, postData } from "../../services/ApiService";
import { acquireEndpoint } from "../../utils/SmartMerchandiserAPI";
import { AxiosResponse } from "axios";
import { ApiServiceHttpResponse } from "../../services/ApiServiceTypes";
import {
  FETCH_CONFIG_REQUEST,
  FETCH_CONFIG_SUCCESS,
  FETCH_CONFIG_FAILURE,
  UPDATE_CONFIG_REQUEST,
  UPDATE_CONFIG_SUCCESS,
  UPDATE_CONFIG_FAILURE,
  CREATE_PRECACHING_JOB_REQUEST,
  CREATE_PRECACHING_JOB_SUCCESS,
  CREATE_PRECACHING_JOB_FAILURE,
  GET_PRECACHING_JOB_REQUEST,
  GET_PRECACHING_JOB_SUCCESS,
  GET_PRECACHING_JOB_FAILURE,
  GET_ALL_PRECACHING_JOBS_REQUEST,
  GET_ALL_PRECACHING_JOBS_SUCCESS,
  GET_ALL_PRECACHING_JOBS_FAILURE,
  CANCEL_PRECACHING_JOB_REQUEST,
  CANCEL_PRECACHING_JOB_SUCCESS,
  CANCEL_PRECACHING_JOB_FAILURE,
  PrecachingCrawlerConfig,
} from "./PrecachingCrawlerTypes";

// All data types that will always be included in precaching operations
const ALL_DATA_TYPES = ["store", "catalog", "category", "product", "color"];

function* fetchPrecachingCrawlerConfig(action: {
  type: string;
  payload: string;
}) {
  try {
    const endpoint = acquireEndpoint("FETCH_CONFIG_REQUEST", action.payload);
    const data: PrecachingCrawlerConfig = yield* call(getData, null, endpoint);

    // Validate required fields are present in response
    const validations = {
      hasData: !!data,
      accountIdIsString: typeof data?.accountId === "string",
      scheduleIsObject: typeof data?.schedule === "object",
      scheduleEnabledIsBoolean: typeof data?.schedule?.enabled === "boolean",
      scheduleCronExpressionIsString:
        typeof data?.schedule?.cronExpression === "string",
      scheduleTimezoneIsString: typeof data?.schedule?.timezone === "string",
      dataTypesIsArray: Array.isArray(data?.dataTypes),
    };

    // Check for validation failures
    const failedValidations = Object.entries(validations)
      .filter(([_, passed]) => !passed)
      .map(([name]) => name);

    if (failedValidations.length > 0) {
      throw new Error("Invalid configuration format received from server");
    }

    // All validations passed, create config object
    const config: PrecachingCrawlerConfig = {
      accountId: data.accountId,
      schedule: {
        enabled: data.schedule.enabled,
        cronExpression: data.schedule.cronExpression,
        timezone: data.schedule.timezone,
      },
      dataTypes: data.dataTypes,
    };

    yield* put({
      type: FETCH_CONFIG_SUCCESS,
      payload: config,
    });
  } catch (error) {
    yield* put({
      type: FETCH_CONFIG_FAILURE,
      payload: error instanceof Error ? error.message : "An error occurred",
    });
  }
}

function* updatePrecachingCrawlerConfig(action: {
  type: string;
  payload: PrecachingCrawlerConfig;
}) {
  try {
    const endpoint = acquireEndpoint(
      "UPDATE_CONFIG_REQUEST",
      action.payload.accountId,
    );

    // Add dataTypes to the payload for the API
    const apiPayload = {
      ...action.payload,
      dataTypes: ALL_DATA_TYPES,
    };

    const response = yield* call(putData, apiPayload, endpoint);

    // The response is the data directly since HttpClientImpl returns response.data
    if (response) {
      // Use the response data if available, otherwise use the request payload
      const configData = response || apiPayload;
      yield* put({
        type: UPDATE_CONFIG_SUCCESS,
        payload: configData,
      });
    } else {
      throw new Error("Failed to update configuration");
    }
  } catch (error) {
    yield* put({
      type: UPDATE_CONFIG_FAILURE,
      payload: error instanceof Error ? error.message : "An error occurred",
    });
    // On failure, fetch the latest config to ensure consistency
    yield* put({
      type: FETCH_CONFIG_REQUEST,
      payload: action.payload.accountId,
    });
  }
}

function* createPrecachingJob(action: {
  type: string;
  payload: { accountId: string; dataTypes: string[] };
}) {
  try {
    const endpoint = acquireEndpoint(
      "CREATE_PRECACHING_JOB",
      action.payload.accountId,
    );

    // Always use all data types regardless of what was passed
    const payload = {
      accountId: action.payload.accountId,
      dataTypes: ALL_DATA_TYPES,
    };

    const response: AxiosResponse<ApiServiceHttpResponse> = yield* call(
      postData,
      payload,
      endpoint,
    );

    if (response?.data?.data) {
      yield* put({
        type: CREATE_PRECACHING_JOB_SUCCESS,
        payload: response.data.data,
      });
    } else {
      throw new Error("Failed to create job");
    }
  } catch (error) {
    yield* put({
      type: CREATE_PRECACHING_JOB_FAILURE,
      payload: error instanceof Error ? error.message : "An error occurred",
    });
  }
}

function* getPrecachingJob(action: {
  type: string;
  payload: { accountId: string; jobId: string };
}) {
  try {
    const endpoint = acquireEndpoint(
      "GET_PRECACHING_JOB",
      action.payload.accountId,
      action.payload.jobId,
    );
    const response: AxiosResponse<ApiServiceHttpResponse> = yield* call(
      getData,
      null,
      endpoint,
    );

    if (response?.data?.data) {
      yield* put({
        type: GET_PRECACHING_JOB_SUCCESS,
        payload: response.data.data,
      });
    } else {
      throw new Error("Failed to fetch job");
    }
  } catch (error) {
    yield* put({
      type: GET_PRECACHING_JOB_FAILURE,
      payload: error instanceof Error ? error.message : "An error occurred",
    });
  }
}

function* getAllPrecachingJobs(action: { type: string; payload: string }) {
  try {
    const endpoint = acquireEndpoint("GET_ALL_PRECACHING_JOBS", action.payload);
    const response: AxiosResponse<ApiServiceHttpResponse> = yield* call(
      getData,
      null,
      endpoint,
    );

    if (response?.data?.data) {
      yield* put({
        type: GET_ALL_PRECACHING_JOBS_SUCCESS,
        payload: response.data.data,
      });
    } else {
      throw new Error("Failed to fetch jobs");
    }
  } catch (error) {
    yield* put({
      type: GET_ALL_PRECACHING_JOBS_FAILURE,
      payload: error instanceof Error ? error.message : "An error occurred",
    });
  }
}

function* cancelPrecachingJob(action: {
  type: string;
  payload: { accountId: string; jobId: string };
}) {
  try {
    const endpoint = acquireEndpoint(
      "CANCEL_PRECACHING_JOB",
      action.payload.accountId,
      action.payload.jobId,
    );
    const response: AxiosResponse<ApiServiceHttpResponse> = yield* call(
      postData,
      null,
      endpoint,
    );

    if (response?.data?.data) {
      yield* put({
        type: CANCEL_PRECACHING_JOB_SUCCESS,
      });
    } else {
      throw new Error("Failed to cancel job");
    }
  } catch (error) {
    yield* put({
      type: CANCEL_PRECACHING_JOB_FAILURE,
      payload: error instanceof Error ? error.message : "An error occurred",
    });
  }
}

export function* watchPrecachingCrawlerOperations() {
  yield* takeLatest(FETCH_CONFIG_REQUEST, fetchPrecachingCrawlerConfig);
  yield* takeLatest(UPDATE_CONFIG_REQUEST, updatePrecachingCrawlerConfig);
  yield* takeLatest(CREATE_PRECACHING_JOB_REQUEST, createPrecachingJob);
  yield* takeLatest(GET_PRECACHING_JOB_REQUEST, getPrecachingJob);
  yield* takeLatest(GET_ALL_PRECACHING_JOBS_REQUEST, getAllPrecachingJobs);
  yield* takeLatest(CANCEL_PRECACHING_JOB_REQUEST, cancelPrecachingJob);
}
