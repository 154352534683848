import { ProductItem } from "store/recommendations/RecommendationType";

export const processProductList = (products: Record<string, ProductItem>) => {
  const filteredEntries = Object.entries(products).filter(
    ([_, value]) => !isNaN(Number(_)) && (value as ProductItem).productCode,
  );

  return {
    productCodes: filteredEntries.map(
      ([_, value]) => (value as ProductItem).productCode,
    ),
    productsData: filteredEntries.map(([_, value]) => ({
      productCode: (value as ProductItem).productCode,
      productName: (value as ProductItem).productName,
    })),
  };
};
