import React, { useCallback, useContext, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, createStyles } from "@material-ui/core";
import { IconButton, styled, Theme, useTheme } from "@mui/material";
import {
  calculateIconSize,
  calculateIconSizeForContentSlot,
  OVERLAY_TABS,
  QUICK_MOVE_DIRECTION,
} from "../utils/Constants";
import { setModalState } from "../store/modal/ModalActions";
import { ViewContext } from "../context/view-context";
import { itemManagementModalId } from "./item-management/ItemManagementModal";
import { ColorManagementModalId } from "./color-mangement-modal/ColorManagement";
import { VariationManagementModalId } from "./variation-management-modal/VariationManagement";
import {
  selectAccountFeatures,
  selectCallerAccountId,
  selectConfigValue,
} from "../store/app-config/AppConfigSelectors";
import { itemManagementSFCCModalId } from "./item-management-SFCC/ItemManagementSFCCModal";
import {
  analyticsDataLoader,
  resetSingleProducAnalyticView,
} from "../store/product/ProductActions";
import AppState from "../store/AppState";
import {
  selectHasChildren,
  selectProductAnalyticsData,
  selectProductFetched,
  selectProductType,
} from "../store/product/ProductSelectors";
import { LeadingImageModalId } from "./leading-image-modal/LeadingImageModal";
import { AdapterInfoObject } from "../utils/AppConfigUtils";
import { selectAdapterInfo } from "../store/adapter-config/AdapterConfigSelectors";
import {
  selectContentSlots,
  selectLockedProductInCurrentCategory,
  selectSavedLockedProducts,
  selectSavedUnlockedProducts,
  selectPinnedProductIds,
  selectStoredPinnedProductIds,
  selectWatchedProductsList,
  selectStoredUnpinnedProductIds,
} from "../store/product-list/ProductListSelectors";
import {
  addProductToWatchList,
  refreshProductFromList,
  removeProductFromWatchList,
  setLockedUnlockedProducts,
  setPinnedUnPinnedProducts,
} from "../store/product-list/ProductListActions";
import {
  selectCurrentLocale,
  selectCurrentStoreId,
} from "../store/store-list/StoreListSelectors";
import { getUserEmail } from "../services/UserService";
import { loadSingleProductAnalytics } from "../store/product/ProductActions";
import {
  selectAnalyticsStartDate,
  selectAnalyticsEndDate,
  selectDateRange,
} from "../store/product/ProductSelectors";
import {
  selectSortingRule,
  selectSortingRulesList,
} from "../store/product-list/ProductListSelectors";
import { BusinessRulesListDetails } from "../store/product-list/ProductListTypes";
import {
  calculateStartEndDate,
  getContentSlotsBeforeTheCurrentPosition,
  isPinnedProduct,
} from "../utils/ProductUtil";
import { selectSystemGeneratedRules } from "../store/businessRules-list/BusinessRuleslistSelectors";
import {
  InventoryIcon,
  VariationIcon,
  AnalyticsIcon,
  HighlightedEyeIcon,
  UnWatchedEyeIcon,
  MoveRightArrowIcon,
  MoveLeftArrowIcon,
  ColorManagementIcon,
  ReassignVariationIcon,
  LockIcon,
  UnlockIcon,
  EllipsisVerticalIcon,
  EllipsisVerticalHoverIcon,
  EllipsisActiveIcon,
  DeleteIcon,
} from "../assets";
import Icons from "./common/Icons";
import { selectOverlay } from "store/overlay/OverlaySelectors";
import CachedIcon from "@material-ui/icons/Cached";
import { selectCurrentCatalogId } from "store/catalog/CatalogSelectors";
import { VariationReassignmentModalId } from "./variation-reassignment-modal/VariationReassignmentModal";
import { selectCurrentCategory } from "store/category/CategorySelectors";
import { ConfirmRemoveContentSlotModalId } from "./ContentSlot/ConfirmRemoveContentSlotModal";
import { FreqBoughtTogetherModalId } from "./freq-bought-together-modal/FreqBoughtTogether";
import { ConfirmAddContentSlotModalId } from "./ContentSlot/ConfirmAddContentSlotModal";

interface StyleProps {
  productActionFZ: string;
  productActionDimensions: string;
  column: number;
}
const useStyles = makeStyles((theme) =>
  createStyles({
    actionContainer: {
      position: "absolute",
      right: " 1em",
      top: "1.9em",
      zIndex: 20,
      "& .product-tile__actions": {
        display: "grid",
        justifyContent: "center",
        alignContent: "center",
        fontSize: (props: StyleProps) => props.productActionFZ,
        height: (props: StyleProps) => props.productActionDimensions,
        width: (props: StyleProps) => props.productActionDimensions,
        "& svg": {
          width: (props) => `${calculateIconSize(props.column)}rem`,
          height: (props) => `${calculateIconSize(props.column)}rem`,
          padding: 2,
        },
        cursor: "pointer",
        marginBottom: "5px",
        borderRadius: "2px",
        background: "#78a6be",
        "& .eyeIcon": {
          padding: (props: StyleProps) => (props.column === 6 ? 2.5 : 5),
        },
        "& .arrowIcon": {
          padding: (props: StyleProps) => (props.column === 6 ? 4 : 7),
        },
        "& .iconStyle": {
          padding: (props: StyleProps) => (props.column === 6 ? 3 : 5),
        },
        "& .pinnedIcon": {
          fontSize: (props: StyleProps) =>
            props.column === 6
              ? 10
              : props.column === 5
                ? 13
                : props.column === 4
                  ? 18
                  : props.column === 3
                    ? 20
                    : 25,
        },
        "& .ellipsisActiveIcon": {
          backgroundColor: "#FFD9CB",
        },
        "& .contentSlotIcon": {
          fontSize: (props) =>
            `${calculateIconSizeForContentSlot(props.column)}rem`,
        },
      },
    },
    leftActionContainer: {
      left: "1em",
      right: "unset",
    },
  }),
);

const StyledInventoryIcon = styled(
  InventoryIcon,
)` &:hover path { fill: #F18221; // Change fill color on hover } `;

const StyledDeleteContentSlotIcon = styled(DeleteIcon)`
  &:hover rect {
    fill: #f18221;
  }
`;

const styles = (theme: Theme) => {
  return {
    highlightedEye: {
      padding: 0,
    },
    activeIcon: {
      backgroundColor: theme.palette.primary.main,
    },
  };
};

interface Props {
  productId: string;
  position: number;
  isSelected: boolean;
  hasProductsSelectedFromList: boolean;
  onQuickMoveCompleteAction: (productId: string, moveTo: number) => void;
  setIsAnalyticsDisplayed: (value: React.SetStateAction<boolean>) => void;
  isAnalyticsDisplayed: boolean;
  isContentSlot?: boolean;
  slotId?: string;
}
const ProductActions = ({
  productId,
  position,
  isSelected,
  hasProductsSelectedFromList,
  onQuickMoveCompleteAction,
  setIsAnalyticsDisplayed,
  isAnalyticsDisplayed,
  isContentSlot = false,
  slotId,
}: Props) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const viewContext = useContext(ViewContext);
  const classes = useStyles({ ...viewContext.styles });
  const theme = useTheme();
  const { highlightedEye, activeIcon } = styles(theme);
  const adapterInfo: AdapterInfoObject = useSelector(selectAdapterInfo);
  const isLeadingImage = adapterInfo?.capabilities?.includes(
    "leading-image",
  ) as boolean;
  const isVariationGroups = adapterInfo?.operations?.includes(
    "GET /v1/{accountId}/products/{baseProductId}/variation-groups",
  ) as boolean;
  const showItemManagement = "true";
  const bulkUpdateEnabled =
    useSelector((state: AppState) =>
      selectConfigValue(state, "bulkUpdateEnabled"),
    ) === "true" || showItemManagement === "true";
  const sequence = position + 1;
  const hasChildren =
    useSelector((state: AppState) => selectHasChildren(state, productId)) ||
    true;
  const productType = useSelector((state: AppState) =>
    selectProductType(state, productId),
  );

  const fetchedProduct = useSelector((state: AppState) =>
    selectProductFetched(state, productId),
  );

  const productTypeCode = useMemo(
    () => fetchedProduct?.typeCode,
    [fetchedProduct],
  );
  const watchedProductsList = useSelector(selectWatchedProductsList);

  const currentStoreId = useSelector(selectCurrentStoreId);

  const localeId = useSelector(selectCurrentLocale) || "";

  const catalogId = useSelector(selectCurrentCatalogId) || "";

  const categoryId = useSelector(selectCurrentCategory);

  const savedLockedProducts = useSelector(selectSavedLockedProducts);

  const savedUnlockedProducts = useSelector(selectSavedUnlockedProducts);

  const contentSlots = useSelector(selectContentSlots);

  const contentSlotsBeforeTheCurrentPosition =
    getContentSlotsBeforeTheCurrentPosition(contentSlots, sequence);
  //Sequence contains the position of content slots, so we need to subtract the number of content slots before the current position to get the updated sequence as we are comparing with locked product position
  const updatedSequence = sequence - contentSlotsBeforeTheCurrentPosition;

  const savedLockedProductsInCategory = savedLockedProducts?.find(
    (product) =>
      product.productId === productId &&
      product.position === updatedSequence &&
      product.catalogId === catalogId &&
      product.categoryId === categoryId,
  );

  const savedUnlockedProductsInCategory = savedUnlockedProducts?.find(
    (product) =>
      product.productId === productId &&
      product.position === updatedSequence &&
      product.catalogId === catalogId &&
      product.categoryId === categoryId,
  );

  const lockedProductsFromDatabase = useSelector((state: AppState) =>
    selectLockedProductInCurrentCategory(
      state,
      categoryId,
      catalogId,
      productId,
      updatedSequence,
    ),
  );

  const isProductLockedInCurrentCategory = savedLockedProductsInCategory
    ? true
    : savedUnlockedProductsInCategory
      ? false
      : lockedProductsFromDatabase
        ? true
        : false;
  const accountId = useSelector(selectCallerAccountId);
  const features = useSelector((state: AppState) =>
    selectAccountFeatures(state, accountId),
  );
  const isLockedProductsFeatureEnabled = features?.["locked-products"] ?? false;
  const isContentSlotsFeatureEnabled = features?.["content-slots"] ?? false;
  const isPinnedProductsFeatureEnabled = features?.["pinned-products"] ?? false;
  const isItemRecommendationsFeatureEnabled =
    features?.["item-recommendations"] ?? false;
  const selectedAccountEmail = useSelector(getUserEmail);

  const isProductAlertsEnabled = useSelector((state: AppState) =>
    selectConfigValue(state, "enableProductAlerts"),
  );

  const productCode = fetchedProduct?.code ?? "";

  const [showHighlightedEye, setShowHighlightedEye] = useState<boolean>(false);
  const [showLock, setShowLock] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState(false);
  const [showProductToggleIcons, setShowProductToggleIcons] = useState(false);

  const productAnalytics = useSelector((state: AppState) =>
    selectProductAnalyticsData(state, productCode),
  );

  const selectedOverlay = useSelector(selectOverlay);
  const startDate = useSelector(selectAnalyticsStartDate);
  const endDate = useSelector(selectAnalyticsEndDate);
  const selectedDateRange = useSelector(selectDateRange);
  const sortingRulesList = useSelector(selectSortingRulesList);
  const systemGeneratedRulesList = useSelector(selectSystemGeneratedRules);
  const allSortingRulesList = [
    ...systemGeneratedRulesList,
    ...sortingRulesList,
  ];

  const selectedRule = useSelector(selectSortingRule);

  const isCurrentProductInWatchlist = useMemo(() => {
    if (watchedProductsList && watchedProductsList.length) {
      return (
        watchedProductsList.findIndex(
          (product) => product.productId === productId,
        ) !== -1
      );
    }
    return false;
  }, [watchedProductsList, productId]);

  const variationBaseId = fetchedProduct?.variationBaseId;
  const isVariationProduct =
    variationBaseId !== "" && variationBaseId !== undefined;
  const hasColors = fetchedProduct?.hasColors;
  const hasManageableColors =
    fetchedProduct?.hasManageableColors && fetchedProduct?.colors.length > 0;

  const storedPinnedProductIds = useSelector(selectStoredPinnedProductIds);
  const pinnedProductIdsFromDB = useSelector(selectPinnedProductIds);
  const storedUnpinnedProductIds = useSelector(selectStoredUnpinnedProductIds);
  const isProductPinned = useMemo(() => {
    return isPinnedProduct(
      storedPinnedProductIds,
      storedUnpinnedProductIds,
      pinnedProductIdsFromDB,
      productId,
    );
  }, [
    storedPinnedProductIds,
    storedUnpinnedProductIds,
    pinnedProductIdsFromDB,
    productId,
  ]);

  const openColorWayManagement = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault();
    dispatch(setModalState(ColorManagementModalId, true, { productId }));
  };

  const openVariationManagement = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault();
    dispatch(
      setModalState(VariationManagementModalId, true, { variationBaseId }),
    );
  };

  const openItemManagement = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault();

    if (bulkUpdateEnabled) {
      dispatch(setModalState(itemManagementModalId, true, { productId }));
    } else {
      dispatch(setModalState(itemManagementSFCCModalId, true, { productId }));
    }
  };

  const openVariationReassignment = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault();
    dispatch(setModalState(VariationReassignmentModalId, true, { productId }));
  };

  const openLeadingImage = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault();
    dispatch(setModalState(LeadingImageModalId, true, { productId }));
  };

  const quickMoveProductsToLeft = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault();
    onQuickMoveCompleteAction(productId, QUICK_MOVE_DIRECTION.LEFT);
  };

  const quickMoveProductsToRight = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault();
    onQuickMoveCompleteAction(productId, QUICK_MOVE_DIRECTION.RIGHT);
  };

  const toggleProductAnalytics = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault();
    if (
      (selectedOverlay !== OVERLAY_TABS.ANALYTICS_OVERLAY &&
        !productAnalytics?.showAnalytics) ||
      (selectedOverlay === OVERLAY_TABS.ANALYTICS_OVERLAY &&
        productAnalytics?.showAnalytics === false)
    ) {
      setIsAnalyticsDisplayed(true);
      dispatch(analyticsDataLoader(productCode));
      const sortingRule = allSortingRulesList?.find(
        (rule) => rule.ruleId === selectedRule,
      );
      if (sortingRule) {
        const { startDate, endDate } = calculateStartEndDate(
          sortingRule as BusinessRulesListDetails,
        );
        if (startDate && endDate) {
          const analyticsPayload = {
            startDate: startDate,
            endDate: endDate,
            productCode: productCode,
          };
          dispatch(loadSingleProductAnalytics(analyticsPayload));
        }
      } else {
        const fromDate = selectedDateRange.startDate;
        const toDate = selectedDateRange.endDate;
        if (fromDate !== startDate || toDate !== endDate) {
          const analyticsPayload = {
            startDate: fromDate,
            endDate: toDate,
            productCode: productCode,
          };
          dispatch(loadSingleProductAnalytics(analyticsPayload));
        }
      }
    } else {
      setIsAnalyticsDisplayed(false);
      dispatch(resetSingleProducAnalyticView(productCode));
    }
  };

  const quickMoveIconsVisibility = isSelected || !hasProductsSelectedFromList;

  const setUnsetWatchState = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault();
      const payload = {
        productId,
        storeId: currentStoreId || "",
        watcher: selectedAccountEmail,
      };
      if (isCurrentProductInWatchlist) {
        dispatch(removeProductFromWatchList(payload));
      } else {
        dispatch(addProductToWatchList(payload));
      }
    },
    [
      dispatch,
      isCurrentProductInWatchlist,
      currentStoreId,
      productId,
      selectedAccountEmail,
    ],
  );

  const setUnsetLockState = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault();
      const payload = {
        productId,
        categoryId,
        catalogId,
        position: updatedSequence,
      };
      if (isProductLockedInCurrentCategory) {
        dispatch(setLockedUnlockedProducts({ ...payload, isLocked: false }));
      } else {
        dispatch(setLockedUnlockedProducts({ ...payload, isLocked: true }));
      }
    },
    [
      dispatch,
      isProductLockedInCurrentCategory,
      productId,
      updatedSequence,
      categoryId,
      catalogId,
    ],
  );

  const fetchSingleProductUpdatedDetails = useCallback(
    (productId: string, event) => {
      event.stopPropagation();
      let payload = {
        productId,
        catalogId,
        localeCode: localeId,
        storeId: currentStoreId || "",
      };
      dispatch(refreshProductFromList(payload));
    },
    [catalogId, localeId, currentStoreId, dispatch],
  );

  const displayProductToggleActions = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault();
      setShowProductToggleIcons(!showProductToggleIcons);
    },
    [showProductToggleIcons],
  );

  const handleDeleteContentSlot = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault();
    if (slotId) {
      dispatch(
        setModalState(ConfirmRemoveContentSlotModalId, true, { slotId }),
      );
    }
  };

  const handleAddSlot = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault();
    const targetPosition = sequence + 1;
    dispatch(
      setModalState(ConfirmAddContentSlotModalId, true, {
        targetPosition,
      }),
    );
  };

  const handlePinProduct = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault();

    if (isProductPinned) {
      dispatch(
        setPinnedUnPinnedProducts({
          productId,
          isPinned: false,
          catalogId,
          categoryId,
        }),
      );
    } else {
      dispatch(
        setPinnedUnPinnedProducts({
          productId,
          isPinned: true,
          catalogId,
          categoryId,
        }),
      );
    }
  };

  const openProductRecommendations = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault();
    dispatch(setModalState(FreqBoughtTogetherModalId, true, { productId }));
  };

  return (
    <>
      {!isContentSlot && (
        <div
          className={`${classes.actionContainer} ${classes.leftActionContainer}`}
        >
          <IconButton
            color="menu"
            aria-label="productToggle"
            component="div"
            onClick={displayProductToggleActions}
            className="product-tile__actions"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            title={intl.formatMessage({
              id: "productActions.productToggle",
              defaultMessage: "Product Toggles",
            })}
          >
            {isHovered && !showProductToggleIcons ? (
              <EllipsisVerticalHoverIcon />
            ) : showProductToggleIcons ? (
              <EllipsisActiveIcon className="ellipsisActiveIcon" />
            ) : (
              <EllipsisVerticalIcon />
            )}
          </IconButton>
          {showProductToggleIcons && (
            <>
              <IconButton
                color="menu"
                title={intl.formatMessage({
                  id: "productActions.analytics",
                  defaultMessage: "View Product Analytics",
                })}
                aria-label="ProductAnalytics"
                component="div"
                onClick={toggleProductAnalytics}
                className="product-tile__actions"
                style={isAnalyticsDisplayed ? activeIcon : {}}
              >
                <AnalyticsIcon />
              </IconButton>
              {isContentSlotsFeatureEnabled && !isContentSlot && (
                <IconButton
                  color="menu"
                  title={intl.formatMessage({
                    id: "contentSlot.add",
                    defaultMessage: "Add Content Slot",
                  })}
                  aria-label="AddContentSlot"
                  component="div"
                  onClick={handleAddSlot}
                  className="product-tile__actions"
                >
                  <i className="fa fa-comments-dollar contentSlotIcon" />
                </IconButton>
              )}
              {isProductAlertsEnabled === "true" && (
                <IconButton
                  component="div"
                  color="menu"
                  className="product-tile__actions"
                  title={
                    isCurrentProductInWatchlist
                      ? intl.formatMessage({
                          id: "productActions.unWatchProduct",
                          defaultMessage: "Unwatch Product",
                        })
                      : intl.formatMessage({
                          id: "productActions.watchProduct",
                          defaultMessage: "Watch Product",
                        })
                  }
                  onMouseOver={() => setShowHighlightedEye(true)}
                  onMouseLeave={() => setShowHighlightedEye(false)}
                  onClick={setUnsetWatchState}
                >
                  {showHighlightedEye ? (
                    <HighlightedEyeIcon style={highlightedEye} />
                  ) : isCurrentProductInWatchlist ? (
                    <HighlightedEyeIcon style={highlightedEye} />
                  ) : (
                    <UnWatchedEyeIcon className="eyeIcon" />
                  )}
                </IconButton>
              )}
              {isLockedProductsFeatureEnabled && (
                <IconButton
                  component="div"
                  color="menu"
                  className="product-tile__actions"
                  title={
                    isProductLockedInCurrentCategory
                      ? intl.formatMessage({
                          id: "productActions.unLockProduct",
                          defaultMessage: "Unlock Product",
                        })
                      : intl.formatMessage({
                          id: "productActions.lockProduct",
                          defaultMessage: "Lock Product",
                        })
                  }
                  onMouseOver={() => setShowLock(true)}
                  onMouseLeave={() => setShowLock(false)}
                  onClick={setUnsetLockState}
                  style={isProductLockedInCurrentCategory ? activeIcon : {}}
                >
                  {showLock && !isProductLockedInCurrentCategory ? (
                    <UnlockIcon className="iconStyle" />
                  ) : isProductLockedInCurrentCategory ? (
                    <LockIcon className="iconStyle" />
                  ) : (
                    <UnlockIcon className="iconStyle" />
                  )}
                </IconButton>
              )}
              {isPinnedProductsFeatureEnabled &&
                !isProductLockedInCurrentCategory && (
                  <IconButton
                    component="div"
                    color="menu"
                    className="product-tile__actions"
                    title={intl.formatMessage({
                      id: "productActions.pinProduct",
                      defaultMessage: "Pin Product",
                    })}
                    onClick={handlePinProduct}
                    style={isProductPinned ? activeIcon : {}}
                  >
                    <i className="fa fa-thumb-tack pinnedIcon" />
                  </IconButton>
                )}
            </>
          )}
        </div>
      )}
      <div className={classes.actionContainer}>
        {!isContentSlot && (
          <IconButton
            color="menu"
            title={intl.formatMessage({
              id: "productActions.refreshIcon",
              defaultMessage: "Refresh Product",
            })}
            aria-label="RefreshProduct"
            component="div"
            onClick={openItemManagement}
            className="product-tile__actions"
          >
            <CachedIcon
              titleAccess={`Last cached at ${new Date(
                fetchedProduct?.cachedAt as Date,
              )} `}
              onClick={(event) =>
                fetchSingleProductUpdatedDetails(productId, event)
              }
              className="refreshIcon"
            />
          </IconButton>
        )}
        {!isContentSlot &&
          hasChildren &&
          productType !== "ProductBundle" &&
          productType !== "ProductSet" && (
            <>
              {hasColors && hasManageableColors && (
                <IconButton
                  color="menu"
                  title={intl.formatMessage({
                    id: "productActions.colorManagement",
                    defaultMessage: "Color Mgmt",
                  })}
                  aria-label="ColorWayManagement"
                  component="div"
                  onClick={openColorWayManagement}
                  className="product-tile__actions"
                >
                  <ColorManagementIcon className="iconStyle" />
                </IconButton>
              )}
              {isVariationProduct &&
                productTypeCode !== "Variation Product" && (
                  <IconButton
                    color="menu"
                    title={intl.formatMessage({
                      id: "productActions.inventoryManagement",
                      defaultMessage: "Inventory Mgmt",
                    })}
                    aria-label="InventoryManagement"
                    component="div"
                    onClick={openItemManagement}
                    className="product-tile__actions"
                  >
                    <StyledInventoryIcon />
                  </IconButton>
                )}
              {isVariationProduct && productTypeCode !== "Variation Group" && (
                <IconButton
                  color="menu"
                  title={intl.formatMessage({
                    id: "productActions.variationReassignment",
                    defaultMessage: "Reassign Variations",
                  })}
                  aria-label="ReassignVariations"
                  component="div"
                  onClick={openVariationReassignment}
                  className="product-tile__actions"
                >
                  <ReassignVariationIcon className="iconStyle" />
                </IconButton>
              )}
              {isVariationGroups &&
                isVariationProduct &&
                productTypeCode !== "Variation Product" && (
                  <IconButton
                    color="menu"
                    title={intl.formatMessage({
                      id: "productActions.variationGroupManagement",
                      defaultMessage: "Variation Group Mgmt",
                    })}
                    aria-label="VariationGroupManagement"
                    component="div"
                    onClick={openVariationManagement}
                    className="product-tile__actions"
                  >
                    <VariationIcon />
                  </IconButton>
                )}
            </>
          )}
        {!isContentSlot &&
          isLeadingImage &&
          productTypeCode !== "Variation Product" && (
            <IconButton
              color="menu"
              title={intl.formatMessage({
                id: "productActions.leadingImage",
                defaultMessage: "Image Mgmt",
              })}
              aria-label="LeadingImage"
              component="div"
              onClick={openLeadingImage}
              className="product-tile__actions"
            >
              <Icons iconId="ImageIcon" type="imagebackgroundhover" />
            </IconButton>
          )}
        {!isContentSlot && isItemRecommendationsFeatureEnabled && (
          <IconButton
            component="div"
            color="menu"
            className="product-tile__actions"
            title={intl.formatMessage({
              id: "productActions.productItemRecommendations",
              defaultMessage: "Freqeuntly Bought Together",
            })}
            onClick={openProductRecommendations}
          >
            <i className="fa-solid fa-wand-magic-sparkles fa-xs"></i>
          </IconButton>
        )}
        {isContentSlotsFeatureEnabled && isContentSlot && (
          <IconButton
            color="menu"
            title={intl.formatMessage({
              id: "contentSlot.delete",
              defaultMessage: "Delete Content Slot",
            })}
            aria-label="DeleteContentSlot"
            component="div"
            onClick={handleDeleteContentSlot}
            className="product-tile__actions"
          >
            <StyledDeleteContentSlotIcon className="iconStyle" />
          </IconButton>
        )}
        {!quickMoveIconsVisibility && !isProductLockedInCurrentCategory && (
          <>
            <IconButton
              color="menu"
              title={intl.formatMessage({
                id: "productActions.moveRight",
                defaultMessage:
                  "Click to move all the selected products to the right of this product",
              })}
              aria-label="quickMoveProductsToRight"
              component="div"
              onClick={quickMoveProductsToRight}
              className="product-tile__actions"
            >
              <MoveRightArrowIcon className="arrowIcon" />
            </IconButton>
            <IconButton
              color="menu"
              title={intl.formatMessage({
                id: "productActions.moveLeft",
                defaultMessage:
                  "Click to move all the selected products to the left of this product",
              })}
              aria-label="quickMoveProductsToLeft"
              component="div"
              onClick={quickMoveProductsToLeft}
              className="product-tile__actions"
            >
              <MoveLeftArrowIcon className="arrowIcon" />
            </IconButton>
          </>
        )}
      </div>
    </>
  );
};

export default ProductActions;
