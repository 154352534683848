import React from "react";
import styled from "styled-components";
import {
  componentClasses,
  StyledCard,
  StyledDiv,
  StyledTypography,
  useStyles,
} from "./GlobalRecommendationsStyles";
import TooltipComponent from "components/common/ToolTip";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAnalyticsEndDate,
  selectAnalyticsStartDate,
  selectDateRange,
  selectProductAnalyticsData,
} from "store/product/ProductSelectors";
import {
  analyticsDataLoader,
  loadSingleProductAnalytics,
  resetSingleProducAnalyticView,
} from "store/product/ProductActions";
import AppState from "store/AppState";
import { useIntl } from "react-intl";
import AnalyticsOverlay from "components/overlays/AnalyticsOverlay";
import { AnalyticsIcon } from "assets";
import { IconButton, useTheme } from "@mui/material";
import { FreqBoughtProductData } from "store/recommendations/RecommendationType";

const ImageContainerNonBase = styled.div`
  display: flex;
  justify-content: center; /* Horizontally centers the container */
  align-items: center;   
  overflow: hidden;
  margin: auto;
  width: 170px;
  height: 230px;
  padding-top: 4px;
  & .image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  }
`;

interface Props {
  product?: FreqBoughtProductData;
  code: string;
  productId: string;
  baseProductId?: string;
  height?: string;
  isColumn?: boolean;
  price?: Number;
  index?: Number;
  onTileClick?: (productId: string | null) => void;
  isAnalyticsDisplayed?: boolean;
  setIsAnalyticsDisplayed?: (value: boolean) => void;
}

const GlobalRecommendationProductItem: React.FC<Props> = (props) => {
  const {
    product,
    code,
    productId,
    isColumn,
    index,
    price,
    baseProductId,
    isAnalyticsDisplayed,
    setIsAnalyticsDisplayed,
    ...otherProps
  } = props;
  const dispatch = useDispatch();
  const intl = useIntl();
  const theme = useTheme();
  const classes = useStyles(theme);
  const startDate = useSelector(selectAnalyticsStartDate);
  const endDate = useSelector(selectAnalyticsEndDate);
  const selectedDateRange = useSelector(selectDateRange);
  const showProductAnalytics = useSelector((state: AppState) =>
    selectProductAnalyticsData(state, code),
  )?.showAnalytics;
  const loadSingleProduct = useSelector((state: AppState) =>
    selectProductAnalyticsData(state, code),
  )?.loadAnalytics;
  const hanldeProductAnalytics = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (!showProductAnalytics) {
      setIsAnalyticsDisplayed?.(true);
      dispatch(analyticsDataLoader(code));
      const fromDate = selectedDateRange.startDate;
      const toDate = selectedDateRange.endDate;
      if (fromDate !== startDate || toDate !== endDate) {
        const analyticsPayload = {
          startDate: fromDate,
          endDate: toDate,
          productCode: product?.code ?? "",
        };
        dispatch(loadSingleProductAnalytics(analyticsPayload));
      }
    } else {
      setIsAnalyticsDisplayed?.(false);
      dispatch(resetSingleProducAnalyticView(code));
    }
  };

  const productImage = product?.thumbnail;

  const renderProductImage = (
    isColumn,
    productImage,
    product,
    componentClasses,
  ) => {
    if (isColumn) {
      return (
        <ImageContainerNonBase>
          {productImage ? (
            <img
              className="image"
              src={productImage}
              alt={product?.name}
              draggable={false}
            />
          ) : (
            <StyledDiv
              className={componentClasses.emptyImageContainer}
            ></StyledDiv>
          )}
        </ImageContainerNonBase>
      );
    }
  };

  const renderProductInfo = (isColumn, product, price, componentClasses) => {
    const calculatePrice = isColumn
      ? price
        ? `${"$" + price}`
        : ""
      : product?.price
        ? `${"$" + product?.price}`
        : "";
    return (
      <StyledDiv
        className={componentClasses.imageProductCodeDiv}
        isColumn={isColumn}
      >
        <TooltipComponent tooltipTitle={product?.name || ""} placement="bottom">
          <StyledTypography
            className={componentClasses.productName}
            isColumn={isColumn}
          >
            {product?.name || ""}
          </StyledTypography>
        </TooltipComponent>
        <TooltipComponent tooltipTitle={`${product?.code}`} placement="bottom">
          <StyledTypography
            className={componentClasses.productCodes}
            isColumn={isColumn}
          >
            {`${product?.code}`}
          </StyledTypography>
        </TooltipComponent>
        <TooltipComponent tooltipTitle={calculatePrice} placement="bottom">
          <StyledTypography
            className={componentClasses.productPrice}
            isColumn={isColumn}
          >
            {calculatePrice}
          </StyledTypography>
        </TooltipComponent>
      </StyledDiv>
    );
  };

  return (
    <StyledDiv>
      <StyledDiv className={componentClasses.productCardContainer}>
        {isColumn && (loadSingleProduct || showProductAnalytics) && (
          <AnalyticsOverlay
            productId={productId}
            showProductAnalytics={showProductAnalytics}
            loadSingleProductAnalytics={loadSingleProduct}
            isSubstituteOverlay={true}
            isRecommendationView={true}
          />
        )}
        <StyledCard
          variant="outlined"
          className={
            !isColumn
              ? componentClasses.baseProductCard
              : componentClasses.productCard
          }
          {...otherProps}
        >
          <StyledDiv className={componentClasses.cardContentDiv}>
            <StyledTypography
              className={componentClasses.productType}
              isColumn={isColumn}
            >
              {product?.typeCode ?? "Product"}
            </StyledTypography>
            <StyledTypography className={componentClasses.stockBase}>
              INV: {product?.stock ?? 0}
              {isColumn && (
                <IconButton
                  size="small"
                  color="menu"
                  title={intl.formatMessage({
                    id: "productActions.analytics",
                    defaultMessage: "View Product Analytics",
                  })}
                  aria-label="ProductAnalytics"
                  component="div"
                  onClick={(event) => {
                    event.stopPropagation();
                    hanldeProductAnalytics(event);
                  }}
                  sx={classes.analyticsIcon}
                >
                  <AnalyticsIcon width={"24.875px"} height={"25.653px"} />
                </IconButton>
              )}
            </StyledTypography>
            {renderProductImage(
              isColumn,
              productImage,
              product,
              componentClasses,
            )}

            {renderProductInfo(isColumn, product, price, componentClasses)}
          </StyledDiv>
        </StyledCard>
      </StyledDiv>
    </StyledDiv>
  );
};

export default GlobalRecommendationProductItem;
