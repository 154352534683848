import { styled, Box, Button, Theme } from "@mui/material";
import { Dialog, FormControl, TextField, Typography } from "@mui/material";

export const styleClasses = {
  root: "root",
  form: "form",
  formControl: "formControl",
  description: "description",
  inputField: "inputField",
  actionButtons: "actionButtons",
  label: "label",
  formModalStyle: "formModalStyle",
  calendarContainer: "calendarContainer",
  error: "error",
  overlay: "overlay",
  precachingCrawlerContainer: "precachingCrawlerContainer",
  buttonContainer: "buttonContainer",
  message: "message",
  crawlerToggle: "crawlerToggle",
  toggleBox: "toggleBox",
  toggleOn: "toggleOn",
  toggleOff: "toggleOff",
  box: "box",
};

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`&.${styleClasses.root}`]: {
    "& .MuiDialog-paper": {
      width: "600px",
      maxWidth: "90vw",
    },
  },
}));

export const StyledForm = styled("form")(({ theme }) => ({
  [`&.${styleClasses.form}`]: {
    margin: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
}));

export const StyledFormControl = styled(FormControl)(({ theme }) => ({
  [`&.${styleClasses.formControl}`]: {
    minWidth: 200,
  },
}));

export const StyledDescription = styled(Typography)(({ theme }) => ({
  [`&.${styleClasses.description}`]: {
    marginBottom: theme.spacing(3),
    fontSize: "16px",
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontFamily: "Helvetica",
  },
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  [`&.${styleClasses.inputField}`]: {
    marginBottom: theme.spacing(2),
    "& .MuiInputBase-root": {
      backgroundColor: theme.palette.background.paper,
    },
  },
}));

export const StyledActionButtons = styled("div")(({ theme }) => ({
  [`&.${styleClasses.actionButtons}`]: {
    display: "flex",
    justifyContent: "flex-end",
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
    padding: theme.spacing(0, 2, 2, 2),
  },
}));

export const StyledLabel = styled("div")(({ theme }) => ({
  [`&.${styleClasses.label}`]: {
    fontSize: "16px",
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontFamily: "Helvetica",
  },
}));

export const StyledFormModal = styled("div")<{ theme: Theme }>(({ theme }) => ({
  [`&.${styleClasses.formModalStyle}`]: {
    minHeight: "420px",
  },
}));

export const StyledCalendarContainer = styled("div")(({ theme }) => ({
  [`&.${styleClasses.calendarContainer}`]: {
    "& .MuiPickersCalendarHeader-root": {
      display: "none",
    },
    "& .MuiDayCalendar-monthContainer": {
      paddingTop: "1.5em",
    },
  },
}));

export const StyledError = styled(Typography)(({ theme }) => ({
  [`&.${styleClasses.error}`]: {
    color: theme.palette.error.main,
    fontSize: "0.75rem",
    marginTop: theme.spacing(0.5),
  },
}));

export const StyledDiv = styled("div")(({ theme }) => ({
  [`&.${styleClasses.overlay}`]: {
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: 99999,
    cursor: "pointer",
  },
  [`&.${styleClasses.precachingCrawlerContainer}`]: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
  },
  [`&.${styleClasses.buttonContainer}`]: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px 0px",
  },
  [`&.${styleClasses.message}`]: {
    marginTop: "5px",
  },
}));

export const StyledToggleDiv = styled("div")(({ theme }) => ({
  [`&.${styleClasses.crawlerToggle}`]: {
    display: "flex",
    alignItems: "flex-end",
    gap: 50,
  },
}));

export const StyledToggleBox = styled(Box)(({ theme }) => ({
  [`&.${styleClasses.toggleBox}`]: {
    borderRadius: "20px",
    position: "relative",
    background: theme.palette.neutral.brightGray,
    boxShadow: "1px 1px 2px rgb(165, 165, 165) inset",
    width: "inherit",
    display: "flex",
    "& .MuiButton-root": {
      borderRadius: "20px",
      minWidth: "50px",
      height: "40px",
      fontWeight: "bold",
      transition: "all 0.2s 0.1s ease",
      padding: 0,
    },
    "& .MuiButton-root:hover": {
      opacity: 0.8,
    },
  },
}));

export const StyledToggleOnButton = styled(Button)<{ toggleType: string }>(
  ({ toggleType, theme }) => ({
    [`&.${styleClasses.toggleOn}`]: {
      color: toggleType === "on" ? "#fff" : theme.palette.text.primary,
      zIndex: 2,
    },
  }),
);

export const StyledToggleOffButton = styled(Button)<{ toggleType: string }>(
  ({ toggleType, theme }) => ({
    [`&.${styleClasses.toggleOff}`]: {
      color: toggleType === "off" ? "#fff" : theme.palette.text.primary,
      zIndex: 2,
    },
  }),
);

export const StyledBox = styled(Box)<{ toggleType: string }>(
  ({ toggleType, theme }) => ({
    [`&.${styleClasses.box}`]: {
      width: 50,
      height: 42,
      borderRadius: 20,
      background: theme.palette.neutral.blue,
      position: "absolute",
      boxShadow: "1px 0 2px " + theme.palette.neutral.boxShadow,
      transition: "all 0.5s ease",
      transform: `translateX(${toggleType === "on" ? 0 : "50px"})`,
    },
  }),
);

export const useStyles = (theme: Theme) => {
  return {
    precachingCrawlerModalDescription: {
      padding: "10px 0px 10px 0px",
      fontSize: "18px",
      fontWeight: 400,
      fontFamily: "Helvetica",
      color: theme.palette.text.primary,
    },
    crawlerToggleBox: {
      display: "flex",
      height: 40,
      width: 100,
    },
    divHeight: {
      height: 40,
    },
  };
};

export const classes = styleClasses;
