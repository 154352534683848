import { Theme, styled, Box, Card, Typography } from "@mui/material";

export const componentClasses = {
  root: "root",
  leftSection: "leftSection",
  draggable: "draggable",
  mainContent: "mainContent",
  mainSection: "mainSection",
  productTiles: "productTiles",
  stock: "stock",
  emptyImageContainer: "emptyImageContainer",
  productCodes: "productCodes",
  productPrice: "productPrice",
  progress: "progress",
  card: "card",
  baseProductCard: "baseProductCard",
  paper: "paper",
  productCard: "productCard",
  imageProductCodeDiv: "imageProductCodeDiv",
  nonPublishedText: "nonPublishedText",
  cardContentDiv: "cardContentDiv",
  stockBase: "stockBase",
  productTypeBase: "productTypeBase",
  noSimilarProducts: "noSimilarProducts",
  productCardContainer: "productCardContainer",
  productType: "productType",
  productName: "productName",
  substituteProducts: "substituteProducts",
  baseProduct: "baseProduct",
  productMainSection: "productMainSection",
  analyticsIcon: "analyticsIcon",
  selectProductToSubstitute: "selectProductToSubstitute",
};

export const StyledCard = styled(Card)({
  [`&.${componentClasses.card}`]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "142px",
    border: "none !important",
  },
  [`&.${componentClasses.baseProductCard}`]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "178px",
    height: "308px",
    border: "0",
  },
  [`&.${componentClasses.productCard}`]: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    alignItems: "center",
    width: "272px",
    height: "410px",
    padding: "4px",
    border: "0",
  },
});

interface StyledDivProps {
  isColumn?: boolean; // Optional prop
  isTileSelected?: boolean;
}
export const StyledTypography = styled(Typography)<StyledDivProps>(
  ({ theme, isColumn = false }) => ({
    [`&.${componentClasses.stockBase}`]: {
      textAlign: "center",
      flex: 1,
      color: theme.palette.common.black,
      fontSize: "14px",
      fontWeight: 700,
      alignContent: "center",
    },
    [`&.${componentClasses.productType}`]: {
      textAlign: "center",
      color: theme.palette.neutral.darkGreyish,
      flex: 1,
      fontSize: "14px",
      fontWeight: 700,
      alignContent: "center",
      paddingTop: "9px",
      paddingBottom: "8px",
    },

    [`&.${componentClasses.productName}`]: {
      display: "block",
      textAlign: "center",
      color: theme.palette.common.black,
      fontSize: "14px",
      fontWeight: 700,
      overflow: "hidden",
      textOverflow: "ellipsis", // Enable ellipsis for overflowed content
      direction: "ltr", // Make the ellipsis appear at the start
      whiteSpace: "nowrap", // Restrict text to a single line
      paddingTop: theme.spacing(1.25),
      paddingBottom: theme.spacing(0.375),
      maxWidth: "100%", // Set a maximum width for the text container
    },
    [`&.${componentClasses.productPrice}`]: {
      display: "block",
      textAlign: "center",
      color: theme.palette.common.black,
      fontSize: "12px",
      fontWeight: 700,
      overflow: "hidden",
      textOverflow: "ellipsis", // Show ellipsis when text exceeds
      direction: "ltr", // Ellipsis at the start
      whiteSpace: "nowrap", // Restrict to one line only
      maxWidth: "100%",
      paddingTop: "4px",
      paddingBottom: theme.spacing(0.375),
    },
    [`&.${componentClasses.productCodes}`]: {
      display: "block",
      textAlign: "center",
      color: theme.palette.common.black,
      fontSize: "12px",
      fontWeight: 400,
      overflow: "hidden",
      textOverflow: "ellipsis", // Enable ellipsis for overflowed content
      direction: "ltr", // Make the ellipsis appear at the start
      whiteSpace: "nowrap", // Restrict text to a single line
      paddingTop: theme.spacing(1.25),
      paddingBottom: theme.spacing(0.375),
      maxWidth: "100%", // Set a maximum width for the text container
    },
    [`&.${componentClasses.productTypeBase}`]: {
      color: theme.palette.common.black,
      fontSize: "14px",
      fontWeight: 700,
      overflow: "hidden",
      textOverflow: "ellipsis", // Show ellipsis when text exceeds
      direction: "ltr", // Ellipsis at the start
      whiteSpace: "nowrap", // Restrict to one line only
      paddingBottom: theme.spacing(1),
      maxWidth: "100%",
      textAlign: "center",
    },
    [`&.${componentClasses.stock}`]: {
      textAlign: "center",
      color: theme.palette.primary.contrastText,
      fontSize: "14px",
      fontWeight: 700,
    },
    [`&.${componentClasses.substituteProducts}`]: {
      fontSize: "16px",
      fontWeight: 700,
      color: theme.palette.neutral.darkGreyish,
    },
    [`&.${componentClasses.baseProduct}`]: {
      paddingBottom: "23px",
      textAlign: "center",
      fontSize: "16px",
      fontWeight: 700,
      color: theme.palette.primary.main,
    },
  }),
);
export const StyledDiv = styled("div")<StyledDivProps>(
  ({ theme, isColumn = false, isTileSelected = false }) => ({
    [`&.${componentClasses.productTiles}`]: {
      display: "flex",
      height: "90%",
      marginBottom: "12px",
    },
    [`&.${componentClasses.leftSection}`]: {
      margin: theme.spacing(0, 3.75, 1.25, 0.625),
      height: "100%",
    },
    [`&.${componentClasses.draggable}`]: {
      width: "100%",
    },
    [`&.${componentClasses.imageProductCodeDiv}`]: {
      width: isColumn ? "200px" : "150px",
      margin: "auto",
    },
    [`&.${componentClasses.mainContent}`]: {
      overflow: "hidden",
      padding: theme.spacing(0, 1.25, 3, 4),
      borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
      zIndex: 1000,
      backgroundColor: "white",
      minHeight: "65vh",
      marginTop: "24px",
      [theme.breakpoints.down("md")]: {
        minWidth: "45%", // Adjust minWidth when screen size reaches 'sm'
        padding: theme.spacing(0, 3),
      },
    },
    [`&.${componentClasses.cardContentDiv}`]: { width: "100%" },
    [`&.${componentClasses.progress}`]: {
      position: "fixed",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      cursor: "pointer",
    },

    [`&.${componentClasses.emptyImageContainer}`]: {
      height: "142px",
      width: "142px",
      background: "lightgray",
      marginBottom: "5px",
      display: "flex",
    },

    [`&.${componentClasses.paper}`]: {
      backgroundColor: theme.palette.background.paper,
      width: "100%",
      minWidth: "65%",
      minHeight: "80vh",
      height: "100%",
      position: "relative",
      display: "flex",
      paddingTop: "24px",
    },
    [`&.${componentClasses.productCardContainer}`]: {
      position: "relative",
      border: isTileSelected
        ? `2px solid ${theme.palette.neutral.blue}`
        : `1px solid ${theme.palette.neutral.ashGrey}`,
      cursor: "pointer",
      background: "#FFF",
      justifyContent: "flex-start",
    },
    [`&.${componentClasses.productMainSection}`]: {
      display: "flex",
      justifyContent: "flex-start",
    },
    [`&.${componentClasses.selectProductToSubstitute}`]: {
      marginLeft: "2px",
    },
  }),
);

export const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${componentClasses.mainSection}`]: {
    display: "flex",
    flexWrap: "wrap",
    gridGap: "24px",
  },
  [`&.${componentClasses.noSimilarProducts}`]: {
    position: "absolute",
    right: "0%",
    left: "0%",
    top: "50%",
    bottom: "50%",
  },
}));

export const useStyles = (theme: Theme) => {
  return {
    analyticsIcon: {
      marginLeft: "auto",
      position: "absolute",
      top: "36px",
      right: "20px",
      borderRadius: "2px",
      padding: "1px",
    },
  };
};
