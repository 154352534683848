import { processProductList } from "utils/RecommendationsUtils";
import {
  FETCH_BESTSELLERS_PRODUCTS,
  FETCH_FREQUENT_BOUGHT_TOGETHER_PRODUCTS,
  FETCH_PRODUCTS_DETAILS_FOR_FREQ_PRODUCTS,
  FETCH_TRENDING_PRODUCTS,
  FreqBoughtProductData,
  freqBoughtTogetherList,
  GlobalRecommendationList,
  RESET_FREQ_BOUGHT_TOGETHER_PRODUCTS,
  RESET_GLOBAL_RECOMMENDATIONS_LIST,
} from "./RecommendationType";

export interface RecommendationListState {
  freqBoughtTogetherList: freqBoughtTogetherList[];
  globalRecommendationList: GlobalRecommendationList[];
  freqBoughtTogetherIds: string[];
  bestsellersProductIds: string[];
  trendingProductIds: string[];
  isFetchingFreqProductsIds: boolean;
  isProductDetailsFetched: boolean;
  isFetchingGlobalRecommendations: boolean;
  isGlobalRecommendations: boolean;
  productsListDeatils: FreqBoughtProductData[];
}

const initialState: RecommendationListState = {
  freqBoughtTogetherList: [],
  freqBoughtTogetherIds: [],
  globalRecommendationList: [],
  trendingProductIds: [],
  bestsellersProductIds: [],
  isFetchingFreqProductsIds: false,
  isProductDetailsFetched: false,
  isFetchingGlobalRecommendations: false,
  isGlobalRecommendations: false,
  productsListDeatils: [],
};

export const RecommendationProductsListReducer = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case FETCH_FREQUENT_BOUGHT_TOGETHER_PRODUCTS.REQUEST: {
      return {
        ...state,
        isFetchingFreqProductsIds: true,
      };
    }
    case FETCH_FREQUENT_BOUGHT_TOGETHER_PRODUCTS.SUCCESS: {
      const { productCodes, productsData } = processProductList(action.payload);
      return {
        ...state,
        freqBoughtTogetherList: productsData,
        freqBoughtTogetherIds: productCodes,
        isFetchingFreqProductsIds: false,
        isProductDetailsFetched: productCodes.length > 0 ? true : false,
      };
    }
    case FETCH_FREQUENT_BOUGHT_TOGETHER_PRODUCTS.FAILURE: {
      return {
        ...state,
        freqBoughtTogetherList: [],
        isFetchingFreqProductsIds: false,
      };
    }
    case FETCH_PRODUCTS_DETAILS_FOR_FREQ_PRODUCTS.REQUEST: {
      return {
        ...state,
        isProductDetailsFetched: true,
      };
    }
    case FETCH_PRODUCTS_DETAILS_FOR_FREQ_PRODUCTS.SUCCESS: {
      const productsList = action?.payload?.results ?? [];
      const productMap = new Map();

      productsList.forEach((item) => {
        if (item.cachedProduct && item.cachedProduct.product.productId) {
          productMap.set(item?.productId, item?.cachedProduct.product);
        }
      });
      const compareProductList = state.isGlobalRecommendations
        ? state.globalRecommendationList
        : state.freqBoughtTogetherList;
      const finalProducts = compareProductList.map(
        ({ productCode, productName }) => {
          if (productMap.has(productCode)) {
            return productMap.get(productCode);
          } else {
            return {
              productId: productCode,
              code: productCode,
              name: productName,
            };
          }
        },
      );
      return {
        ...state,
        isProductDetailsFetched: false,
        productsListDeatils: finalProducts,
      };
    }
    case FETCH_PRODUCTS_DETAILS_FOR_FREQ_PRODUCTS.FAILURE: {
      return {
        ...state,
        isProductDetailsFetched: false,
        productsListDeatils: [],
      };
    }
    case FETCH_BESTSELLERS_PRODUCTS.REQUEST: {
      return {
        ...state,
        isFetchingGlobalRecommendations: true,
      };
    }
    case FETCH_BESTSELLERS_PRODUCTS.SUCCESS: {
      const { productCodes, productsData } = processProductList(action.payload);
      return {
        ...state,
        isGlobalRecommendations: true,
        globalRecommendationList: productsData,
        bestsellersProductIds: productCodes,
        isFetchingGlobalRecommendations: false,
        isProductDetailsFetched: productCodes.length > 0 ? true : false,
      };
    }
    case FETCH_BESTSELLERS_PRODUCTS.FAILURE: {
      return {
        ...state,
        isGlobalRecommendations: false,
        isFetchingGlobalRecommendations: false,
      };
    }
    case FETCH_TRENDING_PRODUCTS.REQUEST: {
      return {
        ...state,
        isFetchingGlobalRecommendations: true,
      };
    }
    case FETCH_TRENDING_PRODUCTS.SUCCESS: {
      const { productCodes, productsData } = processProductList(action.payload);
      return {
        ...state,
        globalRecommendationList: productsData,
        trendingProductIds: productCodes,
        isFetchingGlobalRecommendations: false,
        isGlobalRecommendations: true,
        isProductDetailsFetched: productCodes.length > 0 ? true : false,
      };
    }
    case FETCH_TRENDING_PRODUCTS.FAILURE: {
      return {
        ...state,
        freqBoughtTogetherList: [],
        isFetchingGlobalRecommendations: false,
        isGlobalRecommendations: false,
      };
    }
    case RESET_FREQ_BOUGHT_TOGETHER_PRODUCTS:
      return {
        ...state,
        isProductDetailsFetched: false,
        isFetchingFreqProductsIds: false,
        freqBoughtTogetherIds: [],
        freqBoughtTogetherList: [],
        productsListDeatils: [],
      };

    case RESET_GLOBAL_RECOMMENDATIONS_LIST:
      return {
        ...state,
        isProductDetailsFetched: false,
        isFetchingGlobalRecommendations: false,
        isGlobalRecommendations: false,
        globalRecommendationList: [],
        productsListDeatils: [],
        trendingProductIds: [],
        bestsellersProductIds: [],
      };
    default:
      return state;
  }
};
