import { useIntl } from "react-intl";
import CustomDialog from "../../common/DialogContainer";
import ButtonComponent from "components/common/ButtonComponent";
import { Typography } from "@mui/material";
import AppState from "store/AppState";
import { useSelector } from "react-redux";
import { selectIsModalOpen } from "store/modal/ModalSelectors";

interface ConfirmRemoveBadgeProps {
  handleClose: () => void;
  handleConfirm: () => void;
}

const useStyles = () => {
  return {
    btnGroup: {
      textAlign: "right" as const,
      margin: "20px 20px 10px",
      width: "100%",
    },
    divMessage: {
      marginTop: "5px",
    },
  };
};

const ConfirmRemoveBadges = ({
  handleClose,
  handleConfirm,
}: ConfirmRemoveBadgeProps) => {
  const intl = useIntl();
  const classes = useStyles();
  const isModalOpen = useSelector((state: AppState) =>
    selectIsModalOpen(state, "ConfirmRemoveBadge"),
  );
  const confirmRemoveBadgeModalTitle = () => {
    return (
      <Typography variant="inherit">
        {intl.formatMessage({
          id: "confirmRemoveBadge.header",
          defaultMessage: "Remove Badge",
        })}
      </Typography>
    );
  };

  const confirmRemoveBadgeModalContent = () => {
    return (
      <div style={classes.divMessage}>
        <Typography variant="subtitle1">
          {intl.formatMessage({
            id: "confirmRemoveBadge.instruction",
            defaultMessage: "Are you sure you want to remove the badge?",
          })}
        </Typography>
      </div>
    );
  };

  const dialogActions = () => {
    return (
      <div style={classes.btnGroup}>
        <ButtonComponent
          color="secondaryButtonColorCTABlue"
          variant="outlined"
          onClick={handleClose}
        >
          {intl.formatMessage({
            id: "general.cancel",
            defaultMessage: "Cancel",
          })}
        </ButtonComponent>
        <ButtonComponent
          color="primary"
          variant="contained"
          onClick={() => {
            handleConfirm();
          }}
        >
          {intl.formatMessage({
            id: "general.Proceed",
            defaultMessage: "Proceed",
          })}
        </ButtonComponent>
      </div>
    );
  };

  return (
    <CustomDialog
      open={isModalOpen}
      onClose={handleClose}
      title={confirmRemoveBadgeModalTitle()}
      actions={dialogActions()}
      maxWidth={"md"}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.1)",
          },
        },
      }}
    >
      {confirmRemoveBadgeModalContent()}
    </CustomDialog>
  );
};

export default ConfirmRemoveBadges;
