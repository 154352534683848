import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { CircularProgress, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import AppState from "../../store/AppState";
import { selectProductFetched } from "../../store/product/ProductSelectors";
import {
  selectIsModalOpen,
  selectModalVariable,
} from "../../store/modal/ModalSelectors";
import { setModalState } from "../../store/modal/ModalActions";
import {
  componentClasses,
  StyledBox,
  StyledDiv,
  StyledTypography,
} from "./FreqBoughtTogetherModalStyles";
import { selectCurrentCategory } from "../../store/category/CategorySelectors";
import { VARIATION_GROUP } from "../../utils/Constants";
import CustomDialog from "../common/DialogContainer";
import ButtonComponent from "../common/ButtonComponent";
import { makeStyles, createStyles, Theme } from "@material-ui/core";
import { loadProductsFromCategory } from "store/product-list/ProductListActions";
import { resetAllProducAnalyticsView } from "store/product/ProductActions";
import { selectCurrentCatalogId } from "store/catalog/CatalogSelectors";
import FreqBoughtTogetherProductItem from "./FreqBoughtTogetherProductItem";
import {
  fetchFreqBoughtProductsRequestAction,
  loadProductsDetailsForFreqProducts,
  resetFreqBoughtList,
} from "store/recommendations/RecommendationAction";
import {
  selectFreqBoughtProducts,
  selectFreqProductsDetails,
  selectIsFreqProductsFetched,
  selectIsProductDetailsFetched,
} from "store/recommendations/RecommendationSelector";
import {
  selectCurrentLocale,
  selectCurrentStoreId,
  selectStoreListIds,
} from "store/store-list/StoreListSelectors";
export const FreqBoughtTogetherModalId = "FreqBoughtTogetherModal";

interface Props {
  categoryId: string;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    rightSection: {
      position: "sticky",
      top: 0,
      marginLeft: "auto",
      minWidth: "150px",
      width: "auto",
      height: "74vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(1),
      [theme.breakpoints.down("md")]: {
        minWidth: "200px",
      },
      overflowY: "auto",
      right: 0,
    },
    divActionBtn: {
      display: "flex",
      gap: 17,
      alignItems: "flex-end",
      justifyContent: "flex-end",
      width: "100%",
      margin: "20px",
    },
    divNewActionBtn: { display: "flex" },
    overlay: {
      position: "fixed",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0,0,0,0.5)",
      zIndex: 99999,
      cursor: "pointer",
    },
  }),
);
const FreqBoughtTogether: React.FC<Props> = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const classes = useStyle();
  const [isAnalyticsDisplayed, setIsAnalyticsDisplayed] =
    useState<boolean>(false);
  const categoryId = useSelector(selectCurrentCategory);
  const catalogId = useSelector(selectCurrentCatalogId);
  const freqBoughtTogetherProd = useSelector(selectFreqBoughtProducts);
  const localeCode = useSelector(selectCurrentLocale);
  const storeListIds = useSelector(selectStoreListIds);
  const storeId = useSelector(selectCurrentStoreId);
  const productId = useSelector((state: AppState) =>
    selectModalVariable(state, FreqBoughtTogetherModalId, "productId"),
  );
  const fetchedProduct = useSelector((state: AppState) =>
    selectProductFetched(state, productId),
  );
  const isFreqProductDetailsFetched = useSelector(
    selectIsProductDetailsFetched,
  );
  const isFetchingFreqProductsIds = useSelector(selectIsFreqProductsFetched);
  const freqProductDetails = useSelector(selectFreqProductsDetails);
  const targetProduct = useMemo(() => {
    if (fetchedProduct?.productId) {
      return {
        productId: fetchedProduct.productId,
        code: fetchedProduct.code,
        name: fetchedProduct.name,
        thumbnail: fetchedProduct.thumbnail,
        isPublished: fetchedProduct.isPublished,
        storeSpecific:
          fetchedProduct?.storeSpecific &&
          Object.entries(fetchedProduct.storeSpecific).map((e) => {
            return { storeId: e[0], isPublished: e[1].isPublished };
          }),
        translations: fetchedProduct?.translations,
        stock: fetchedProduct.stock,
        baseProductId:
          fetchedProduct?.typeCode === VARIATION_GROUP
            ? fetchedProduct.productId
            : fetchedProduct.variationBaseId,
        price: fetchedProduct.highPrice,
        colors: fetchedProduct.colors,
        categorySpecific: fetchedProduct.categorySpecific,
        hasSequenceableColors: fetchedProduct.hasSequenceableColors,
        typeCode: fetchedProduct.typeCode,
      };
    }
  }, [fetchedProduct]);

  const isModalOpen = useSelector((state: AppState) =>
    selectIsModalOpen(state, FreqBoughtTogetherModalId),
  );

  const handleClose = () => {
    dispatch(setModalState(FreqBoughtTogetherModalId, false));
    dispatch(resetFreqBoughtList());
    dispatch(resetAllProducAnalyticsView());
    setIsAnalyticsDisplayed(false);
  };

  useEffect(() => {
    if (freqBoughtTogetherProd.length > 0 && storeId) {
      let payload: Parameters<typeof loadProductsFromCategory>[0] = {
        categoryId,
        catalogId,
        localeCode,
        storeId,
        productIds: freqBoughtTogetherProd,
        storeListIds,
        isRefreshingAllProducts: true,
      };
      dispatch(loadProductsDetailsForFreqProducts(payload));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, freqBoughtTogetherProd]);

  useEffect(() => {
    if (isModalOpen && targetProduct && targetProduct?.productId) {
      const payload = {
        productId: targetProduct.productId,
        period: 30,
        limit: 8,
      };
      dispatch(fetchFreqBoughtProductsRequestAction(payload));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isModalOpen]);

  const rightSection = () => {
    return (
      <>
        <div className={classes.divActionBtn}>
          <ButtonComponent
            color="primary"
            variant="contained"
            onClick={() => handleClose()}
          >
            {intl.formatMessage({
              id: "general.close",
              defaultMessage: "Close",
            })}
          </ButtonComponent>
        </div>
      </>
    );
  };

  const FreqBoughtTogetherModalTitle = () => {
    return (
      <Typography variant="inherit">
        Frequently Bought Together - {fetchedProduct?.name ?? ""}{" "}
        {`(${fetchedProduct?.code})`}
      </Typography>
    );
  };

  const freqBoughtTogetherModalContent = () => {
    return (
      <>
        <StyledDiv className={componentClasses.paper}>
          <StyledDiv className={componentClasses.leftSection}>
            <StyledTypography className={componentClasses.baseProduct}>
              {intl.formatMessage({
                id: "frequentlyBoughtTogether.baseProductTitle",
                defaultMessage: "Product",
              })}
            </StyledTypography>
            {targetProduct && (
              <FreqBoughtTogetherProductItem
                product={targetProduct}
                code={targetProduct?.code}
                productId={targetProduct?.productId}
                isColumn={false}
              />
            )}
          </StyledDiv>
          <StyledDiv>
            <StyledDiv
              className={componentClasses.selectProductToSubstitute}
            ></StyledDiv>
            <StyledDiv className={componentClasses.mainContent}>
              <StyledBox className={componentClasses.mainSection}>
                {isFetchingFreqProductsIds || isFreqProductDetailsFetched ? (
                  <StyledDiv className={componentClasses.progress}>
                    <CircularProgress style={{ margin: "auto" }} size="40px" />
                  </StyledDiv>
                ) : freqProductDetails && freqProductDetails.length > 0 ? (
                  freqProductDetails?.map((product, productIndex) => (
                    <FreqBoughtTogetherProductItem
                      product={product}
                      code={product?.code ?? ""}
                      price={product?.highPrice}
                      productId={product?.productId ?? ""}
                      isColumn={true}
                      index={productIndex}
                      key={productIndex.toString()}
                      isAnalyticsDisplayed={isAnalyticsDisplayed}
                      setIsAnalyticsDisplayed={setIsAnalyticsDisplayed}
                    />
                  ))
                ) : (
                  <StyledBox className={componentClasses.noSimilarProducts}>
                    <Typography variant="h5" style={{ textAlign: "center" }}>
                      {intl.formatMessage({
                        id: "freqBoughtTogether.noProductsFoundText",
                        defaultMessage:
                          "No frequently bought together products found",
                      })}
                    </Typography>
                  </StyledBox>
                )}
              </StyledBox>
            </StyledDiv>
          </StyledDiv>
        </StyledDiv>
      </>
    );
  };

  return (
    <CustomDialog
      open={isModalOpen}
      onClose={() => handleClose()}
      title={FreqBoughtTogetherModalTitle()}
      fullWidth
      maxWidth={"lg"}
      onClick={(e) => e.preventDefault()}
      actions={rightSection()}
    >
      {freqBoughtTogetherModalContent()}
    </CustomDialog>
  );
};

export default React.memo(FreqBoughtTogether);
