import styled from "styled-components";
import { Theme } from "@mui/material/styles";

export const StyledContentSlot = styled.div<{ theme: Theme }>`
  position: relative;
  border: 3.5px dotted ${({ theme }) => theme.palette.neutral.neutralGrey} !important;
  box-shadow: none;
  opacity: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0.8em;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.background.default};
  padding-top: 15px;
`;

export const ImageContainer = styled.div<{
  theme: Theme;
}>`
  overflow: hidden;
  & .dollar {
    color: ${({ theme }) => theme.palette.neutral.neutralGrey};
  }
`;

export const StyledContentSlotContent = styled.div`
  padding-bottom: 15px;
  padding-left: 13%;
`;

export const StyledContentSlotImage = styled.div<{
  columns: number;
}>`
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;
  height: ${({ columns }) =>
    columns === 2 || columns === 3
      ? "60%"
      : columns === 4 || columns === 5
        ? "45%"
        : "40%"};
  font-size: ${({ columns }) =>
    columns === 2
      ? "180px"
      : columns === 3
        ? "150px"
        : columns === 4
          ? "120px"
          : columns === 5
            ? "100px"
            : "90px"};
`;

export const StyledContentSlotSelectedOverlay = styled.div<{ theme: Theme }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.2 !important;
  background-color: ${({ theme }) => theme.palette.neutral.shakeSpeareColor};
`;

export const useStyles = () => {
  return {
    contentSlot: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "75%",
    },
    emptyDiv: {
      height: "15px",
    },
  };
};
