import {
  GET_BESTSELLERS_PROD_LIST,
  GET_FREQ_BOUGHT_TOGETHER_LIST,
  GET_TRENDING_PROD_LIST,
  getFreqBoughtTogetherPayload,
  getGlobalRecommendationPayload,
  GetProductListPayload,
  LOAD_PRODUCT_DETAILS_FOR_FREQ_PRODUCTS,
  RESET_FREQ_BOUGHT_TOGETHER_PRODUCTS,
  RESET_GLOBAL_RECOMMENDATIONS_LIST,
} from "./RecommendationType";

export const fetchFreqBoughtProductsRequestAction = (
  payload: getFreqBoughtTogetherPayload,
) => {
  return {
    type: GET_FREQ_BOUGHT_TOGETHER_LIST,
    payload,
  };
};

export const fetchTrendingProductsRequestAction = (
  payload: getGlobalRecommendationPayload,
) => {
  return {
    type: GET_TRENDING_PROD_LIST,
    payload,
  };
};

export const fetchBestsellersProductsRequestAction = (
  payload: getGlobalRecommendationPayload,
) => {
  return {
    type: GET_BESTSELLERS_PROD_LIST,
    payload,
  };
};

export const loadProductsDetailsForFreqProducts = (
  payload: GetProductListPayload,
) => {
  return {
    payload,
    type: LOAD_PRODUCT_DETAILS_FOR_FREQ_PRODUCTS,
  };
};

export const resetFreqBoughtList = () => {
  return {
    type: RESET_FREQ_BOUGHT_TOGETHER_PRODUCTS,
  };
};

export const resetGlobalRecommendationList = () => {
  return {
    type: RESET_GLOBAL_RECOMMENDATIONS_LIST,
  };
};
