import React from "react";
import GlobalRecommendationsModal, {
  TrendingProductsModalId,
} from "./GlobalRecommendationsModal";

const TrendingProductsModal = () => {
  return (
    <GlobalRecommendationsModal
      modalId={TrendingProductsModalId}
      title="Trending Products"
      productType="trending"
      noProductsMessageId="trending.noProductsFoundText"
    />
  );
};

export default React.memo(TrendingProductsModal);
