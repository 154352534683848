import React, { useContext, useState, MouseEvent } from "react";
import ProductActions from "../ProductActions";
import { ViewContext } from "context/view-context";
import { Box, Typography, useTheme } from "@mui/material";
import { useIntl } from "react-intl";
import {
  ImageContainer,
  StyledContentSlot,
  StyledContentSlotContent,
  StyledContentSlotImage,
  StyledContentSlotSelectedOverlay,
  useStyles,
} from "./ContentSlotStyles";

interface Props {
  slotId: string;
  position: number;
  onQuickMoveCompleteAction: (slotId: string, moveTo: number) => void;
  productView: boolean;
  isSelected: boolean;
  hasSlotsSelectedFromList: boolean;
  onSelectionChange: (
    itemId: string,
    cmdKeyActive: boolean,
    shiftKeyActive: boolean,
  ) => void;
}

const ContentSlot: React.FC<Props> = ({
  slotId,
  position,
  onQuickMoveCompleteAction,
  productView,
  isSelected,
  hasSlotsSelectedFromList,
  onSelectionChange,
}) => {
  const [isHover, setIsHover] = useState(false);
  const view = useContext(ViewContext);
  const columns = view.styles.column;
  const intl = useIntl();
  const changeStyles =
    columns === 6 ? { fontSize: "0.75rem" } : { fontSize: "inherit" };
  const classes = useStyles();
  const theme = useTheme();

  const selectionHandler = (e: MouseEvent<HTMLDivElement>) => {
    if (!e.isDefaultPrevented()) {
      onSelectionChange(slotId, e.metaKey || e.ctrlKey, e.shiftKey);
    }
  };

  return (
    <StyledContentSlot
      theme={theme}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={selectionHandler}
    >
      {isSelected && <StyledContentSlotSelectedOverlay theme={theme} />}
      <StyledContentSlotContent>
        <Box>
          <Box>
            <Typography
              style={changeStyles}
              sx={classes.contentSlot}
              variant="subHeader"
            >
              {intl.formatMessage({
                id: "contentSlot.title",
                defaultMessage: "Content Slot",
              })}
            </Typography>
          </Box>
          <Box>
            <Typography
              style={changeStyles}
              sx={classes.contentSlot}
              variant="subtitle2"
            />
          </Box>
          <Box>
            <div style={classes.emptyDiv}> </div>
          </Box>
        </Box>
      </StyledContentSlotContent>
      <StyledContentSlotImage columns={columns}>
        <ImageContainer theme={theme}>
          <i className="fa-solid fa-comments-dollar dollar"></i>
        </ImageContainer>
      </StyledContentSlotImage>
      {productView && isHover && (
        <ProductActions
          slotId={slotId}
          productId={""}
          position={position}
          isSelected={isSelected}
          hasProductsSelectedFromList={hasSlotsSelectedFromList}
          onQuickMoveCompleteAction={onQuickMoveCompleteAction}
          setIsAnalyticsDisplayed={() => {}}
          isAnalyticsDisplayed={false}
          isContentSlot={true}
        />
      )}
    </StyledContentSlot>
  );
};

export default React.memo(ContentSlot);
