export const FETCH_FREQUENT_BOUGHT_TOGETHER_PRODUCTS = {
  REQUEST: "FETCH_FREQUENT_BOUGHT_TOGETHER_PRODUCTS_REQUEST",
  SUCCESS: "FETCH_FREQUENT_BOUGHT_TOGETHER_PRODUCTS_SUCCESS",
  FAILURE: "FETCH_FREQUENT_BOUGHT_TOGETHER_PRODUCTS_FAILURE",
};

export const RESET_FREQ_BOUGHT_TOGETHER_PRODUCTS =
  "RESET_FREQ_BOUGHT_TOGETHER_PRODUCTS";

export const LOAD_PRODUCT_DETAILS_FOR_FREQ_PRODUCTS =
  "LOAD_PRODUCT_DETAILS_FOR_FREQ_PRODUCTS";
export const FETCH_PRODUCTS_DETAILS_FOR_FREQ_PRODUCTS = {
  START: "FETCH_PRODUCTS_DETAILS_FOR_FREQ_PRODUCTS_START",
  REQUEST: "FETCH_PRODUCTS_DETAILS_FOR_FREQ_PRODUCTS_REQUEST",
  SUCCESS: "FETCH_PRODUCTS_DETAILS_FOR_FREQ_PRODUCTS_SUCCESS",
  FAILURE: "FETCH_PRODUCTS_DETAILS_FOR_FREQ_PRODUCTS_FAILURE",
  STOP: "FETCH_PRODUCTS_DETAILS_FOR_FREQ_PRODUCTS_STOP",
};

export const GET_FREQ_BOUGHT_TOGETHER_LIST = "GET_FREQ_BOUGHT_TOGETHER_LIST";

export const FETCH_TRENDING_PRODUCTS = {
  REQUEST: "FETCH_TRENDING_PRODUCTS_REQUEST",
  SUCCESS: "FETCH_TRENDING_PRODUCTS_SUCCESS",
  FAILURE: "FETCH_TRENDING_PRODUCTS_FAILURE",
};

export const FETCH_BESTSELLERS_PRODUCTS = {
  REQUEST: "FETCH_BESTSELLERS_PRODUCTS_REQUEST",
  SUCCESS: "FETCH_BESTSELLERS_PRODUCTS_SUCCESS",
  FAILURE: "FETCH_BESTSELLERS_PRODUCTS_FAILURE",
};

export const GET_BESTSELLERS_PROD_LIST = "GET_BESTSELLERS_LIST";
export const GET_TRENDING_PROD_LIST = "GET_TRENDING_LIST";
export const RESET_GLOBAL_RECOMMENDATIONS_LIST =
  "RESET_GLOBAL_RECOMMENDATIONS_LIST";

export interface getFreqBoughtTogetherPayload {
  productId: string;
  limit: number;
  period: number;
}

export interface getGlobalRecommendationPayload {
  limit: number;
  period: number;
}

export interface freqBoughtTogetherList {
  productCode: string;
  productName: string;
}

export interface GlobalRecommendationList {
  productCode: string;
  productName: string;
}

export interface FreqBoughtTogetherAction {
  type: typeof GET_FREQ_BOUGHT_TOGETHER_LIST;
  payload: {
    productId: string;
    limit: number;
    period: number;
  };
}

export interface ProductListAction<T extends string> {
  type: T;
  payload: {
    limit: number;
    period: number;
  };
}

export interface TrendingProductAction
  extends ProductListAction<typeof GET_TRENDING_PROD_LIST> {}

export interface BestsellersProductAction
  extends ProductListAction<typeof GET_BESTSELLERS_PROD_LIST> {}

export interface GetProductListPayload {
  catalogId: string;
  categoryId: string;
  localeCode: string | null;
  storeId: string;
  next?: string;
  count?: number;
  productIds?: string[];
  totalPagesFetched?: Number;
}

export interface loadProductsForFreqProductsAction {
  payload: GetProductListPayload;
  type: typeof LOAD_PRODUCT_DETAILS_FOR_FREQ_PRODUCTS;
}

export interface ProductItem {
  productCode: string;
  productName: string;
  [key: string]: any;
}

export interface FreqBoughtProductData {
  productId?: string;
  code: string;
  type?: string;
  colorId?: string;
  name?: string;
  swatchImage?: string;
  baseProductId?: string | undefined;
  thumbnail?: string;
  isPublished?: boolean;
  storeSpecific?:
    | { storeId: string; isPublished: boolean | null }[]
    | undefined;
  translations?: [
    {
      localeCode: string;
      name: string;
    },
  ];
  stock: number;
  hasSequenceableColors?: boolean;
  price?: Number;
  bounds?: ClientRect | DOMRect | null;
  isUpdatingPublishFlag?: boolean;
  lowPrice?: number;
  listPrice?: number;
  highPrice?: number;
  typeCode?: string;
  stockValue?: number;
}
