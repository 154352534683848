import {
  PrecachingCrawlerState,
  PrecachingCrawlerActionTypes,
  FETCH_CONFIG_REQUEST,
  FETCH_CONFIG_SUCCESS,
  FETCH_CONFIG_FAILURE,
  UPDATE_CONFIG_REQUEST,
  UPDATE_CONFIG_SUCCESS,
  UPDATE_CONFIG_FAILURE,
} from "./PrecachingCrawlerTypes";

const initialState: PrecachingCrawlerState = {
  config: null,
  isFetching: false,
  isUpdating: false,
  error: null,
};

export const precachingCrawlerReducer = (
  state = initialState,
  action: PrecachingCrawlerActionTypes,
): PrecachingCrawlerState => {

  switch (action.type) {
    case FETCH_CONFIG_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case FETCH_CONFIG_SUCCESS:
      return {
        ...state,
        isFetching: false,
        config: action.payload,
        error: null,
      };
    case FETCH_CONFIG_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case UPDATE_CONFIG_REQUEST:
      return {
        ...state,
        isUpdating: true,
        error: null,
        // Apply optimistic update
        config: action.payload,
      };
    case UPDATE_CONFIG_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        config: action.payload,
        error: null,
      };
    case UPDATE_CONFIG_FAILURE:
      return {
        ...state,
        isUpdating: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
