import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Box,
  CircularProgress,
  useTheme,
  SelectChangeEvent,
  Alert,
} from "@mui/material";
import { Formik, Form, useField, FormikProps } from "formik";
import * as Yup from "yup";
import moment from "moment-timezone";
import CustomDialog from "../../common/DialogContainer";
import ButtonComponent from "components/common/ButtonComponent";
import { setModalState } from "../../../store/modal/ModalActions";
import { selectIsModalOpen } from "../../../store/modal/ModalSelectors";
import { selectCallerAccountId } from "../../../store/app-config/AppConfigSelectors";
import AppState from "../../../store/AppState";
import DropdownMenu from "../../common/DropdownMenu";
import {
  StyledDiv,
  StyledToggleDiv,
  StyledToggleBox,
  StyledBox,
  StyledToggleOnButton,
  StyledToggleOffButton,
  styleClasses,
  useStyles,
} from "./PrecachingCrawlerStyles";
import {
  fetchPrecachingCrawlerConfig,
  updatePrecachingCrawlerConfig,
  // createPrecachingJob,
} from "../../../store/precaching-crawler/PrecachingCrawlerActions";
import {
  selectPrecachingCrawlerConfig,
  selectIsFetchingConfig,
  selectIsUpdatingConfig,
  selectPrecachingCrawlerError,
} from "../../../store/precaching-crawler/PrecachingCrawlerSelectors";

export const PrecachingCrawlerModalId = "PrecachingCrawlerModal";

// Type definitions
type Hour =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23;
type Minute =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23
  | 24
  | 25
  | 26
  | 27
  | 28
  | 29
  | 30
  | 31
  | 32
  | 33
  | 34
  | 35
  | 36
  | 37
  | 38
  | 39
  | 40
  | 41
  | 42
  | 43
  | 44
  | 45
  | 46
  | 47
  | 48
  | 49
  | 50
  | 51
  | 52
  | 53
  | 54
  | 55
  | 56
  | 57
  | 58
  | 59;

interface SelectProps {
  name: keyof FormValues;
  label: string;
  menuOptions: Array<Hour | Minute | string>;
  width?: string;
  defaultValue?: string;
}

interface FormValues {
  hour: Hour | null;
  minute: Minute | null;
  timezone: string;
}

interface FormState extends FormValues {
  isEnabled: boolean;
}

interface LoadingState {
  isFetching: boolean;
  isUpdating: boolean;
  isTriggering: boolean;
  loadingMessage?: string;
}

// Constants
const timezones = moment.tz.names();
const currentUserTimezone = moment.tz.guess();

const hours: Hour[] = Array.from({ length: 24 }, (_, i) => i as Hour);
const minutes: Minute[] = Array.from({ length: 60 }, (_, i) => i as Minute);

const initialFormState: FormState = {
  hour: null,
  minute: null,
  timezone: currentUserTimezone,
  isEnabled: false,
};

const validationSchema = Yup.object().shape({
  hour: Yup.number()
    .nullable()
    .required("Hour is required")
    .min(0, "Hour must be between 0 and 23")
    .max(23, "Hour must be between 0 and 23"),
  minute: Yup.number()
    .nullable()
    .required("Minute is required")
    .min(0, "Minute must be between 0 and 59")
    .max(59, "Minute must be between 0 and 59"),
  timezone: Yup.string().required("Timezone is required"),
});

const PrecachingCrawlerModal: React.FC = (): React.ReactElement => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles(theme);
  const accountId = useSelector(selectCallerAccountId);
  const [formState, setFormState] = useState<FormState>(initialFormState);
  const [localError, setLocalError] = useState<string | null>(null);
  const [loadingState, setLoadingState] = useState<LoadingState>({
    isFetching: false,
    isUpdating: false,
    isTriggering: false,
  });

  const isOpen = useSelector((state: AppState) =>
    selectIsModalOpen(state, PrecachingCrawlerModalId),
  );
  const formikRef = useRef<FormikProps<FormValues>>(null);

  const config = useSelector(selectPrecachingCrawlerConfig);
  const isFetchingFromStore = useSelector(selectIsFetchingConfig);
  const isUpdatingFromStore = useSelector(selectIsUpdatingConfig);
  const error = useSelector(selectPrecachingCrawlerError);

  // Track initial form values
  const [initialValues, setInitialValues] = useState<FormValues>({
    hour: null,
    minute: null,
    timezone: currentUserTimezone,
  });

  // Fetch initial configuration when modal opens
  useEffect(() => {
    if (isOpen && accountId) {
      dispatch(fetchPrecachingCrawlerConfig(accountId));
    }
  }, [isOpen, accountId, dispatch]);

  // Update initial values when config changes
  useEffect(() => {
    if (config?.schedule.enabled) {
      const cronParts = config.schedule.cronExpression.split(" ");
      const utcHour = parseInt(cronParts[1]);
      const utcMinute = parseInt(cronParts[0]);

      const utcTime = moment
        .utc()
        .startOf("day")
        .hour(utcHour)
        .minute(utcMinute);
      const localTime = utcTime.tz(config.schedule.timezone);

      const hour = localTime.hour() as Hour;
      const minute = localTime.minute() as Minute;

      setInitialValues({
        hour,
        minute,
        timezone: config.schedule.timezone,
      });

      setFormState({
        hour,
        minute,
        timezone: config.schedule.timezone,
        isEnabled: true,
      });
    } else {
      setInitialValues({
        hour: null,
        minute: null,
        timezone: currentUserTimezone,
      });
    }
  }, [config]);

  useEffect(() => {
    if (error) {
      setLocalError(error);
    }
  }, [error]);

  // Update loading state when Redux state changes
  useEffect(() => {
    setLoadingState((prev) => ({
      ...prev,
      isFetching: isFetchingFromStore,
      loadingMessage: isFetchingFromStore
        ? intl.formatMessage({
            id: "precachingCrawler.loading.fetching",
            defaultMessage: "Loading configuration...",
          })
        : prev.loadingMessage,
    }));
  }, [isFetchingFromStore, intl]);

  useEffect(() => {
    setLoadingState((prev) => ({
      ...prev,
      isUpdating: isUpdatingFromStore,
      loadingMessage: isUpdatingFromStore
        ? intl.formatMessage({
            id: "precachingCrawler.loading.updating",
            defaultMessage: "Updating configuration...",
          })
        : prev.loadingMessage,
    }));
  }, [isUpdatingFromStore, intl]);

  const handleClose = () => {
    setLocalError(null);
    dispatch(setModalState(PrecachingCrawlerModalId, false));
  };

  const handleSubmit = async (values: FormValues) => {
    try {
      setLocalError(null);
      setLoadingState((prev) => ({
        ...prev,
        isUpdating: true,
        loadingMessage: intl.formatMessage({
          id: "precachingCrawler.loading.updating",
          defaultMessage: "Updating configuration...",
        }),
      }));

      if (!accountId) {
        throw new Error(
          intl.formatMessage({
            id: "precachingCrawler.error.noAccount",
            defaultMessage:
              "No account selected. Please select an account and try again.",
          }),
        );
      }

      const localTime = moment.tz(
        `${values.hour}:${values.minute}`,
        "H:m",
        values.timezone,
      );

      if (!localTime.isValid()) {
        throw new Error(
          intl.formatMessage({
            id: "precachingCrawler.error.invalidTime",
            defaultMessage: "Invalid time format. Please check your input.",
          }),
        );
      }

      const utcTime = localTime.clone().tz("UTC");

      const payload = {
        accountId,
        schedule: {
          enabled: formState.isEnabled,
          cronExpression: `${utcTime.minutes()} ${utcTime.hours()} * * *`,
          timezone: values.timezone,
        },
        dataTypes: ["store", "catalog", "category", "product", "color"],
      };

      await dispatch(updatePrecachingCrawlerConfig(payload));
      setFormState((prev) => ({
        ...prev,
        ...values,
      }));
    } catch (err) {
      const errorMessage =
        err instanceof Error
          ? err.message
          : intl.formatMessage({
              id: "precachingCrawler.error.unknown",
              defaultMessage: "An unknown error occurred. Please try again.",
            });
      setLocalError(errorMessage);
      console.error("Error updating configuration:", err);
    } finally {
      setLoadingState((prev) => ({
        ...prev,
        isUpdating: false,
        loadingMessage: undefined,
      }));
    }
  };

  const handleToggleChange = (isEnabled: boolean) => {
    try {
      setLocalError(null);
      setLoadingState((prev) => ({
        ...prev,
        isUpdating: true,
        loadingMessage: intl.formatMessage({
          id: "precachingCrawler.loading.toggling",
          defaultMessage: isEnabled
            ? "Enabling crawler..."
            : "Disabling crawler...",
        }),
      }));

      if (!accountId) {
        throw new Error(
          intl.formatMessage({
            id: "precachingCrawler.error.noAccount",
            defaultMessage:
              "No account selected. Please select an account and try again.",
          }),
        );
      }

      if (!isEnabled) {
        // Reset form state when turning off
        const newState = {
          ...initialFormState,
          isEnabled: false,
        };
        setFormState(newState);

        if (formikRef.current) {
          formikRef.current.resetForm({
            values: {
              hour: null,
              minute: null,
              timezone: currentUserTimezone,
            },
          });
        }

        // Send update request
        dispatch(
          updatePrecachingCrawlerConfig({
            accountId,
            schedule: {
              enabled: false,
              cronExpression: "0 0 * * *",
              timezone: currentUserTimezone,
            },
            dataTypes: ["store", "catalog", "category", "product", "color"],
          }),
        );
      } else {
        // Set default values when turning on
        const defaultHour = 0 as Hour;
        const defaultMinute = 0 as Minute;

        const newState = {
          hour: defaultHour,
          minute: defaultMinute,
          timezone: currentUserTimezone,
          isEnabled: true,
        };
        setFormState(newState);

        if (formikRef.current) {
          formikRef.current.setValues({
            hour: defaultHour,
            minute: defaultMinute,
            timezone: currentUserTimezone,
          });
        }

        const localTime = moment.tz(
          `${defaultHour}:${defaultMinute}`,
          "H:m",
          currentUserTimezone,
        );
        const utcTime = localTime.clone().tz("UTC");

        dispatch(
          updatePrecachingCrawlerConfig({
            accountId,
            schedule: {
              enabled: true,
              cronExpression: `${utcTime.minutes()} ${utcTime.hours()} * * *`,
              timezone: currentUserTimezone,
            },
            dataTypes: ["store", "catalog", "category", "product", "color"],
          }),
        );
      }
    } catch (err) {
      const errorMessage =
        err instanceof Error
          ? err.message
          : intl.formatMessage({
              id: "precachingCrawler.error.unknown",
              defaultMessage: "An unknown error occurred. Please try again.",
            });
      setLocalError(errorMessage);
      console.error("Error during toggle change:", err);
    } finally {
      setLoadingState((prev) => ({
        ...prev,
        isUpdating: false,
        loadingMessage: undefined,
      }));
    }
  };

  // const handleTriggerJob = async () => {
  //   try {
  //     setLocalError(null);
  //     setLoadingState((prev) => ({
  //       ...prev,
  //       isTriggering: true,
  //       loadingMessage: intl.formatMessage({
  //         id: "precachingCrawler.loading.triggering",
  //         defaultMessage: "Triggering precaching job...",
  //       }),
  //     }));

  //     if (!accountId) {
  //       throw new Error(
  //         intl.formatMessage({
  //           id: "precachingCrawler.error.noAccount",
  //           defaultMessage:
  //             "No account selected. Please select an account and try again.",
  //         }),
  //       );
  //     }
  //     await dispatch(
  //       createPrecachingJob(accountId, [
  //         "store",
  //         "catalog",
  //         "category",
  //         "product",
  //         "color",
  //       ]),
  //     );
  //   } catch (err) {
  //     const errorMessage =
  //       err instanceof Error
  //         ? err.message
  //         : intl.formatMessage({
  //             id: "precachingCrawler.error.unknown",
  //             defaultMessage:
  //               "An unknown error occurred while triggering the job.",
  //           });
  //     setLocalError(errorMessage);
  //     console.error("Error triggering job:", err);
  //   } finally {
  //     setLoadingState((prev) => ({
  //       ...prev,
  //       isTriggering: false,
  //       loadingMessage: undefined,
  //     }));
  //   }
  // };

  const getModalTitle = () => (
    <Typography variant="inherit">
      {intl.formatMessage({
        id: "precachingCrawler.title",
        defaultMessage: "Precaching Schedule",
      })}
    </Typography>
  );

  const SelectWrapper: React.FC<SelectProps> = ({
    name,
    label,
    menuOptions = [],
    width = "",
    defaultValue,
  }) => {
    const [field, meta, helpers] = useField<FormValues[keyof FormValues]>(name);
    const { setValue } = helpers;

    const handleChange = (event: SelectChangeEvent<string | number>) => {
      const value = event.target.value;

      if (label === "Minute") {
        const minuteValue = Number(value) as Minute;
        setFormState((prev) => ({ ...prev, minute: minuteValue }));
        setValue(minuteValue);
      } else if (label === "Hour") {
        const hourValue = Number(value) as Hour;
        setFormState((prev) => ({ ...prev, hour: hourValue }));
        setValue(hourValue);
      } else if (label === "Timezone") {
        const timezoneValue = value as string;
        setFormState((prev) => ({ ...prev, timezone: timezoneValue }));
        setValue(timezoneValue);
      }
    };

    return (
      <>
        <DropdownMenu
          menuId="PrecachingCrawlerModal"
          value={
            field.value !== null && field.value !== undefined ? field.value : ""
          }
          label={label}
          changeHandler={handleChange}
          menuOptions={menuOptions}
        />
        {meta.touched && meta.error ? (
          <div style={{ color: "red", fontSize: "12px" }}>{meta.error}</div>
        ) : null}
      </>
    );
  };

  const modalDescription = (id: string, message: string) => (
    <StyledDiv className={styleClasses.message}>
      <Typography
        style={classes.precachingCrawlerModalDescription}
        variant="body1"
      >
        {intl.formatMessage({
          id: id,
          defaultMessage: message,
        })}
      </Typography>
    </StyledDiv>
  );

  const LoadingOverlay = () => (
    <StyledDiv className={styleClasses.overlay}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
        }}
      >
        <CircularProgress />
        {loadingState.loadingMessage && (
          <Typography variant="body2" color="textSecondary">
            {loadingState.loadingMessage}
          </Typography>
        )}
      </Box>
    </StyledDiv>
  );

  return (
    <Box style={{ maxWidth: "450px" }}>
      <CustomDialog
        open={isOpen}
        onClose={handleClose}
        title={getModalTitle()}
        maxWidth="md"
      >
        <div>
          {(loadingState.isFetching ||
            loadingState.isUpdating ||
            loadingState.isTriggering) && <LoadingOverlay />}
          {(error || localError) && (
            <Box sx={{ mb: 2 }}>
              <Alert
                severity="error"
                onClose={() => setLocalError(null)}
                sx={{
                  "& .MuiAlert-message": {
                    width: "100%",
                  },
                }}
              >
                {localError || error}
              </Alert>
            </Box>
          )}
          <StyledToggleDiv className={styleClasses.crawlerToggle}>
            {formState.isEnabled
              ? modalDescription(
                  "precachingCrawler.modalDescription",
                  "Please enter the time when the daily job to precache data should run",
                )
              : modalDescription(
                  "precachingCrawler.toggle",
                  "Please turn on the precaching crawler and schedule it.",
                )}
            <Box sx={classes.crawlerToggleBox}>
              <StyledToggleBox className={styleClasses.toggleBox}>
                <StyledBox
                  toggleType={formState.isEnabled ? "on" : "off"}
                  className={styleClasses.box}
                />
                <StyledToggleOnButton
                  className={styleClasses.toggleOn}
                  toggleType={formState.isEnabled ? "on" : "off"}
                  disableRipple
                  variant="text"
                  onClick={() => handleToggleChange(true)}
                >
                  {intl.formatMessage({
                    id: "precachingCrawler.toggleOn",
                    defaultMessage: "on",
                  })}
                </StyledToggleOnButton>
                <StyledToggleOffButton
                  className={styleClasses.toggleOff}
                  toggleType={formState.isEnabled ? "on" : "off"}
                  disableRipple
                  variant="text"
                  onClick={() => handleToggleChange(false)}
                >
                  {intl.formatMessage({
                    id: "precachingCrawler.toggleOff",
                    defaultMessage: "off",
                  })}
                </StyledToggleOffButton>
              </StyledToggleBox>
            </Box>
          </StyledToggleDiv>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            innerRef={formikRef}
          >
            {({ values, isValid, dirty }) => {
              const hasChanges =
                JSON.stringify(values) !== JSON.stringify(initialValues);
              return (
                <Form>
                  {formState.isEnabled && (
                    <>
                      <StyledDiv
                        className={styleClasses.precachingCrawlerContainer}
                      >
                        <SelectWrapper
                          name="hour"
                          menuOptions={hours}
                          label="Hour"
                          width="100px"
                        />
                        <SelectWrapper
                          name="minute"
                          menuOptions={minutes}
                          label="Minute"
                          width="100px"
                        />
                        <SelectWrapper
                          name="timezone"
                          menuOptions={timezones}
                          label="Timezone"
                          width="150px"
                          defaultValue={currentUserTimezone}
                        />
                      </StyledDiv>
                      <StyledDiv
                        className={styleClasses.buttonContainer}
                        style={{
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        {/* <ButtonComponent
                          variant="contained"
                          color="primary"
                          onClick={handleTriggerJob}
                          disabled={
                            loadingState.isTriggering || loadingState.isUpdating
                          }
                        >
                          {loadingState.isTriggering
                            ? intl.formatMessage({
                                id: "precachingCrawler.triggering",
                                defaultMessage: "Triggering...",
                              })
                            : intl.formatMessage({
                                id: "precachingCrawler.triggerJob",
                                defaultMessage: "Trigger Job Now",
                              })}
                        </ButtonComponent> */}
                        <div style={{ display: "flex", gap: "8px" }}>
                          <ButtonComponent
                            color="secondaryButtonColorCTABlue"
                            variant="outlined"
                            onClick={handleClose}
                            disabled={
                              loadingState.isUpdating ||
                              loadingState.isTriggering
                            }
                          >
                            {intl.formatMessage({
                              id: "precachingCrawler.cancel",
                              defaultMessage: "Cancel",
                            })}
                          </ButtonComponent>
                          <ButtonComponent
                            color="primary"
                            variant="contained"
                            type="submit"
                            disabled={
                              !isValid ||
                              !hasChanges ||
                              loadingState.isUpdating ||
                              loadingState.isTriggering
                            }
                          >
                            {loadingState.isUpdating
                              ? intl.formatMessage({
                                  id: "precachingCrawler.saving",
                                  defaultMessage: "Saving...",
                                })
                              : intl.formatMessage({
                                  id: "precachingCrawler.save",
                                  defaultMessage: "Save",
                                })}
                          </ButtonComponent>
                        </div>
                      </StyledDiv>
                    </>
                  )}
                </Form>
              );
            }}
          </Formik>
        </div>
      </CustomDialog>
    </Box>
  );
};

export default PrecachingCrawlerModal;
