import React from "react";
import { Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import AppState from "../../store/AppState";
import {
  selectIsModalOpen,
  selectModalVariable,
} from "../../store/modal/ModalSelectors";
import { setModalState } from "../../store/modal/ModalActions";
import { useIntl } from "react-intl";
import CustomDialog from "../common/DialogContainer";
import ButtonComponent from "../common/ButtonComponent";
import {
  discardOutOfPositionProducts,
  updateProductListInState,
  updateProductListSequenceMode,
} from "store/product-list/ProductListActions";
import {
  selectLockedProducts,
  selectPinnedProductIds,
  selectProductListsFetched,
} from "store/product-list/ProductListSelectors";
import { selectCurrentCategory } from "store/category/CategorySelectors";
import { selectCurrentCatalogId } from "store/catalog/CatalogSelectors";
import { SEQUENCE_MODE_TYPE } from "utils/Constants";
import { reorderProductsList } from "utils/ProductUtil";
import { selectAdapterInfo } from "store/adapter-config/AdapterConfigSelectors";
import { AdapterInfoObject } from "utils/AppConfigUtils";

export const ConfirmOutOfPositionProductsModalId =
  "ConfirmOutOfPositionProductsModal";

const useStyles = () => {
  return {
    dailogAction: {
      width: "100%",
      textAlign: "end" as const,
      margin: "20px 20px 10px",
    },
    divMessage: {
      marginTop: "5px",
    },
  };
};

const ConfirmOutOfPositionProducts = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const intl = useIntl();
  const categoryId = useSelector(selectCurrentCategory);
  const currentCatalogId = useSelector(selectCurrentCatalogId);
  const productsList = useSelector(selectProductListsFetched);

  const isModalOpen = useSelector((state: AppState) =>
    selectIsModalOpen(state, ConfirmOutOfPositionProductsModalId),
  );
  const outOfPositionPinnedProducts = useSelector((state: AppState) =>
    selectModalVariable(
      state,
      ConfirmOutOfPositionProductsModalId,
      "pinnedProducts",
    ),
  );
  const outOfPositionLockedProducts = useSelector((state: AppState) =>
    selectModalVariable(
      state,
      ConfirmOutOfPositionProductsModalId,
      "lockedProducts",
    ),
  );
  const lockedProductsInCategory = useSelector((state: AppState) =>
    selectLockedProducts(state, categoryId, currentCatalogId),
  );
  const adapterInfo: AdapterInfoObject = useSelector(selectAdapterInfo);
  const isUnmanagedSupported = adapterInfo?.capabilities?.includes(
    "unmanaged-products",
  ) as boolean;
  const pinnedProducts = useSelector(selectPinnedProductIds);

  const handleClose = () => {
    dispatch(setModalState(ConfirmOutOfPositionProductsModalId, false));
  };

  const handleDiscard = () => {
    let reorderedPinnedProducts: string[] = [];
    let isProductsOutsidePinnedArea = false;

    if (outOfPositionPinnedProducts.length > 0) {
      // Get indices of all products with their status
      const productIndices = productsList.map((product, index) => ({
        productId: product.productId,
        index,
        isPinned: pinnedProducts.includes(product.productId),
        isUnlocked:
          !lockedProductsInCategory.some(
            (lock) => lock.productId === product.productId,
          ) || outOfPositionLockedProducts.includes(product.productId),
      }));

      // Find the first unlocked product that's not pinned
      const firstUnlockedIndex = productIndices.findIndex(
        (product) => product.isUnlocked && !product.isPinned,
      );

      // Find the last pinned index before any unlocked products
      const lastPinnedIndex =
        firstUnlockedIndex === -1
          ? Math.max(
              ...productIndices.filter((p) => p.isPinned).map((p) => p.index),
              -1,
            )
          : Math.max(
              ...productIndices
                .filter((p) => p.index < firstUnlockedIndex && p.isPinned)
                .map((p) => p.index),
              -1,
            );

      // Check if any out-of-position products are beyond the pinned area
      const productsOutsidePinnedArea = outOfPositionPinnedProducts.filter(
        (productId) =>
          productsList.findIndex((p) => p.productId === productId) >
          lastPinnedIndex,
      );

      isProductsOutsidePinnedArea = productsOutsidePinnedArea.length > 0;
      reorderedPinnedProducts = productsList
        .filter(
          (product) =>
            pinnedProducts.includes(product.productId) &&
            !productsOutsidePinnedArea.includes(product.productId),
        )
        .map((product) => product.productId);
    }

    // Get matching locked products that are out of position
    const matchingProducts =
      outOfPositionLockedProducts.length > 0
        ? lockedProductsInCategory.filter((lockedProduct) =>
            outOfPositionLockedProducts.includes(lockedProduct.productId),
          )
        : [];

    dispatch(
      discardOutOfPositionProducts({
        catalogId: currentCatalogId,
        categoryId: categoryId,
        pinnedProductIds: reorderedPinnedProducts,
        sortOption: isUnmanagedSupported ? "unmanaged" : "manual",
        unlockedProducts: matchingProducts,
        isPinnedProductsOutOfPosition: outOfPositionPinnedProducts.length > 0,
        isProductsOutsidePinnedArea,
      }),
    );

    dispatch(setModalState(ConfirmOutOfPositionProductsModalId, false));
  };

  const handleRestore = () => {
    const updatedProductSequenceList = reorderProductsList(
      productsList,
      lockedProductsInCategory,
      pinnedProducts,
    );
    dispatch(
      updateProductListInState({
        products: updatedProductSequenceList,
        isSaveAction: true,
      }),
    );
    dispatch(updateProductListSequenceMode(SEQUENCE_MODE_TYPE.EDIT));
    dispatch(setModalState(ConfirmOutOfPositionProductsModalId, false));
  };

  const confirmationOutOfPositionProductsTitle = () => {
    return (
      <Typography variant="inherit">
        {" "}
        {intl.formatMessage({
          id: "confirmationOutOfPositionProducts.modalTitle",
          defaultMessage: "Warning: Out-of-position Products",
        })}
      </Typography>
    );
  };

  const confirmationOutOfPositionProductsContent = () => {
    return (
      <div style={classes.divMessage}>
        <Typography variant="subtitle1">
          {intl.formatMessage({
            id: "confirmOutOfPositionProducts.instruction",
            defaultMessage:
              "Some products are no longer in their locked or pinned positions. Would you like to discard or restore the locks and pins?",
          })}
        </Typography>
      </div>
    );
  };

  const dialogActions = () => {
    return (
      <div style={classes.dailogAction}>
        <ButtonComponent
          color="secondaryButtonColorCTABlue"
          variant="outlined"
          onClick={handleDiscard}
        >
          {intl.formatMessage({
            id: "general.discard",
            defaultMessage: "Discard",
          })}
        </ButtonComponent>
        <ButtonComponent
          color="primary"
          variant="contained"
          onClick={handleRestore}
        >
          {intl.formatMessage({
            id: "general.restore",
            defaultMessage: "Restore",
          })}
        </ButtonComponent>
      </div>
    );
  };

  return (
    <CustomDialog
      open={isModalOpen}
      onClose={handleClose}
      title={confirmationOutOfPositionProductsTitle()}
      actions={dialogActions()}
      maxWidth={"md"}
    >
      {confirmationOutOfPositionProductsContent()}
    </CustomDialog>
  );
};

export default React.memo(ConfirmOutOfPositionProducts);
