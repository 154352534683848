import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useContext,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { FixedSizeGrid } from "react-window";
import { useResizeDetector } from "react-resize-detector";
import { useDndScrolling } from "react-dnd-scrolling";
import ProductTileWrapper from "../ProductTileWrapper";
import {
  setPinnedUnPinnedProducts,
  updateProductListInState,
  updateProductListSequenceMode,
} from "../../store/product-list/ProductListActions";
import { ProductSequenceData } from "../../store/product-list/ProductListTypes";
import {
  OVERLAY_TABS,
  QUICK_MOVE_DIRECTION,
  SEQUENCE_MODE_TYPE,
} from "../../utils/Constants";
import AppState from "../../store/AppState";
import {
  selectIsContentSlotLoading,
  selectIsFetchingProductIds,
  selectLockedProductInCurrentCategory,
  selectPinnedProductIds,
  selectProductListSequenceMode,
  selectStoredPinnedProductIds,
} from "../../store/product-list/ProductListSelectors";
import { ViewContext } from "../../context/view-context";
import { DragItem } from "../ProductTile";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import {
  resetAllProducAnalyticsView,
  updateProductListBySearch,
} from "../../store/product/ProductActions";
import { selectGetProductIds } from "../../store/product-list/ProductListSelectors";
import {
  selectIsFetchedBySearch,
  selectProducts,
  selectRecentlyAddedNewProducts,
} from "../../store/product/ProductSelectors";
import { PRODUCT_TILE_HEIGHTS } from "../../utils/Constants";
import { useQuery } from "../../hooks/useQueryParams";
import { CircularProgress } from "@material-ui/core";
import useClipboard from "../../hooks/useClipBoard";
import {
  selectCurrentStoreId,
  selectStoresLoadedState,
} from "../../store/store-list/StoreListSelectors";
import { StyledDiv, styleClasses } from "./ProductListStyles";
import { selectOverlay } from "store/overlay/OverlaySelectors";
import {
  selectCurrentCatalogId,
  selectIsCatalogsLoading,
} from "store/catalog/CatalogSelectors";
import {
  selectCurrentCategory,
  selectIsTopCategoriesLoading,
} from "store/category/CategorySelectors";
import { badgeConfigureMessage } from "store/product-badge/ProductBadgeActions";
import { selectProductBadgeList } from "store/product-badge/ProductSelectors";
import { getContentSlotsBeforeTheCurrentPosition } from "utils/ProductUtil";

export interface SelectedSwatch {
  selectedSwatch: string;
  productImage: string;
}
export interface SelectedSwatchNode {
  [name: string]: SelectedSwatch;
}

interface ContentSlotData {
  slotId: string;
  position: number;
  type: string;
}

interface Props {
  products: ProductSequenceData[];
  contentSlots?: ContentSlotData[];
}

const getLockedPositions = (
  items: ProductSequenceData[],
  lockedProductSelector: (productId: string, position: number) => any,
) => {
  return items
    .map((item, index) => ({
      productId: item.productId,
      isLocked: Boolean(lockedProductSelector(item.productId, index + 1)),
      position: index,
    }))
    .filter(({ isLocked }) => isLocked);
};

const ProductList: React.FC<Props> = (props) => {
  const { products, contentSlots = [] } = props;
  const query = useQuery();
  const categoryPathQuery = query.get("categoryPath") ?? "";
  const [selectedItems, setSelectedItems] = useState<ProductSequenceData[]>([]);
  const [draggedItemsIds, setDraggedItemsIds] = useState<string[]>([]);
  const [activeItemId, setActiveItemId] = useState("");
  const [hoveredItemIndex, setHoveredItemIndex] = useState(-1);
  const [insertLineIndex, setInsertLineIndex] = useState(-1);
  const [newSequence, setNewSequence] = useState<any[]>();
  const viewContext = useContext(ViewContext);
  const insertIndexRef = useRef({ insertIndex: -1 });
  const { width, height, ref } = useResizeDetector();
  const isFetchedBySearch = useSelector(selectIsFetchedBySearch);
  const recentlyAddedNewProducts = useSelector(selectRecentlyAddedNewProducts);
  const productIds = useSelector(selectGetProductIds);
  const isStoresLoaded = useSelector(selectStoresLoadedState);
  const isCatalogsLoading = useSelector(selectIsCatalogsLoading);
  const isTopCategoriesLoading = useSelector(selectIsTopCategoriesLoading);
  const isFetchingProductIds = useSelector(selectIsFetchingProductIds);
  const isContentSlotLoading = useSelector(selectIsContentSlotLoading);
  const productById = useSelector(selectProducts);

  const dispatch = useDispatch();
  const { addClipboardProductCodes } = useClipboard();
  const sequenceMode = useSelector((state: AppState) =>
    selectProductListSequenceMode(state),
  );
  const selectedOverlay = useSelector(selectOverlay);

  const categoryId = useSelector(selectCurrentCategory);
  const catalogId = useSelector(selectCurrentCatalogId);
  const storedPinnedProductIds = useSelector(selectStoredPinnedProductIds);
  const pinnedProducts = useSelector(selectPinnedProductIds);

  const lockedProductSelector = useSelector(
    (state: AppState) => (productId: string, position: number) =>
      selectLockedProductInCurrentCategory(
        state,
        categoryId,
        catalogId,
        productId,
        position,
      ),
  );

  const memoizedContentSlots = useMemo(() => contentSlots, [contentSlots]);

  const processProductList = useCallback(
    (productList: ProductSequenceData[]) => {
      if (memoizedContentSlots.length === 0) {
        return productList;
      }

      // First, identify existing content slots and their positions
      const existingContentSlots = productList.reduce(
        (acc, product, index) => {
          if (product.slotId) {
            acc[index + 1] = { ...product, position: index + 1 };
          }
          return acc;
        },
        {} as Record<number, any>,
      );

      // Sort new slots in ascending order
      const sortedSlots = [...memoizedContentSlots].sort(
        (a, b) => a.position - b.position,
      );

      // Calculate total positions needed
      const totalPositions = productList.length + sortedSlots.length;

      // Create final array
      const finalList = new Array(totalPositions);

      // Place existing content slots
      Object.values(existingContentSlots).forEach((item) => {
        finalList[item.position - 1] = item;
      });

      // Add new content slots
      sortedSlots.forEach((slot) => {
        finalList[slot.position - 1] = {
          ...slot,
          productId: slot.slotId,
        };
      });

      // Fill remaining positions with products
      let productIndex = 0;
      for (let i = 0; i < finalList.length; i++) {
        if (!finalList[i] && productIndex < productList.length) {
          const product = productList[productIndex];
          // Skip if product is already a content slot
          if (!product.slotId) {
            finalList[i] = {
              ...product,
              sequence: productIndex,
            };
            productIndex++;
          }
        }
      }

      // Remove any undefined entries
      return finalList.filter(Boolean);
    },
    [memoizedContentSlots],
  );

  function findCommonObjectsAndMerge(array1, array2) {
    const commonObjects: any[] = [];
    const uniqueObjects: any[] = [];

    // Create a set of IDs from the second array for faster lookup
    const idSet = new Set(array2?.map((obj) => obj?.productId));
    // Loop through the first array
    for (let obj of array1) {
      // Check if the object's ID exists in the second array
      if (idSet.has(obj?.productId) && obj.sequence === 0) {
        const updatedObj = { ...obj, isRecentlyAdded: true };
        commonObjects.push(updatedObj);
      } else {
        uniqueObjects.push(obj);
      }
    }
    // Merge commonObjects and uniqueObjects
    const mergedArray = [...commonObjects, ...uniqueObjects];

    // To make the list unique, you can use a Set and then convert it back to an array
    const uniqueMergedArray = Array.from(new Set(mergedArray));

    return uniqueMergedArray;
  }

  function doesIdExist(array, targetId) {
    for (const item of array) {
      const id = Object.keys(item)[0];
      if (id === targetId.toString()) {
        return true;
      }
    }
    return false;
  }

  // Memoize the sequence update logic
  const updateSequence = useCallback(
    (productList: ProductSequenceData[]) => {
      // Only update if the new sequence is different
      setNewSequence((prevSequence) => {
        const processedList = processProductList(productList);
        // Check if the new sequence is different from the previous one
        if (JSON.stringify(prevSequence) === JSON.stringify(processedList)) {
          return prevSequence;
        }
        return processedList;
      });
    },
    [processProductList],
  );

  React.useEffect(() => {
    if (recentlyAddedNewProducts.length) {
      const categoryTree = categoryPathQuery.split(";");
      const categoryId = categoryTree[categoryTree.length - 1];
      const exists = doesIdExist(recentlyAddedNewProducts, categoryId);
      if (exists) {
        const relevantData = recentlyAddedNewProducts.find(
          (data) => Object.keys(data)[0] === categoryId,
        );

        if (relevantData) {
          const uniqueList = findCommonObjectsAndMerge(
            products,
            relevantData[categoryId],
          );
          updateSequence(uniqueList);
        }
      } else {
        updateSequence(products);
      }
    } else {
      updateSequence(products);
    }
  }, [products, categoryPathQuery, recentlyAddedNewProducts, updateSequence]);

  const resetSelectedItem = useCallback(() => {
    if (selectedItems.length > 0) {
      setSelectedItems([]);
    }
  }, [selectedItems.length]);

  useOnClickOutside(ref, resetSelectedItem);

  const itemMoveHandler = useCallback(
    (hoverIndex: number, insertLineIndex: number) => {
      setHoveredItemIndex(hoverIndex);
      setInsertLineIndex(insertLineIndex);
    },
    [],
  );

  const itemDragStartHandler = useCallback((dragItem: DragItem) => {
    setSelectedItems(dragItem.items);
    setDraggedItemsIds(dragItem.items.map((item) => item.productId));
    setActiveItemId(dragItem.draggedItem.productId);
  }, []);

  /**
   * Calculates the actual target index where items will be inserted in the remaining items array.
   * This is needed because:
   * 1. The insertIndex is based on the original list (copiedItems) which includes locked and dragged items
   * 2. We need to map this position to the corresponding position in remainingItems (which excludes dragged items)
   * 3. If the target position is locked, we need to find the next available position
   */
  const getEffectiveTargetIndex = (
    insertIndex: number,
    copiedItems: ProductSequenceData[],
    remainingItems: ProductSequenceData[],
    lockedIds: Set<string>,
  ) => {
    let targetIndex = -1;

    if (insertIndex >= 0 && insertIndex < copiedItems.length) {
      const targetItem = copiedItems[insertIndex];
      if (!lockedIds.has(targetItem.productId)) {
        // If target position is not locked, find its corresponding index in remainingItems
        targetIndex = remainingItems.findIndex(
          (item) => item.productId === targetItem.productId,
        );
      } else {
        // If target is locked, find the next available unlocked position
        let currentIndex = insertIndex + 1;
        while (targetIndex < 0 && currentIndex < copiedItems.length) {
          const itemId = copiedItems[currentIndex].productId;
          if (!lockedIds.has(itemId)) {
            targetIndex = remainingItems.findIndex(
              (item) => item.productId === itemId,
            );
          }
          currentIndex++;
        }
      }
    }
    // If no valid position found, return the insert index
    return targetIndex < 0 ? insertIndex : targetIndex;
  };

  const getLastPinnedPosition = (
    items: ProductSequenceData[],
    pinnedIds: Set<string>,
    lockedIds: Set<string>,
    draggedItems: ProductSequenceData[],
  ) => {
    let lastPosition = -1;
    items.forEach((item, index) => {
      if (
        !lockedIds.has(item.productId) &&
        !draggedItems.includes(item) &&
        pinnedIds.has(item.productId)
      ) {
        lastPosition = index;
      }
    });
    return lastPosition;
  };

  const shouldUpdatePinning = (
    item: ProductSequenceData,
    targetIndex: number,
    originalPosition: number,
    lastPinnedPosition: number,
    remainingItems: ProductSequenceData[],
    dragItem: DragItem,
    lockedIds: Set<string>,
    pinnedIds: Set<string>,
  ) => {
    // Skip if returning to original position
    if (targetIndex === originalPosition) return null;

    // Check for pinned items after target position
    let hasPinnedItemsAfter = false;
    for (let i = targetIndex + 1; i < remainingItems.length; i++) {
      const currentItem = remainingItems[i];
      if (
        !dragItem.items.includes(currentItem) &&
        !lockedIds.has(currentItem.productId) &&
        pinnedIds.has(currentItem.productId)
      ) {
        hasPinnedItemsAfter = true;
        break;
      }
    }

    const shouldBePinned =
      targetIndex <= lastPinnedPosition || hasPinnedItemsAfter;
    return shouldBePinned;
  };

  const dragCompleteHandler = useCallback(
    (dragItem: DragItem, index: number) => {
      if (dragItem) {
        const copiedItems = products.slice();
        const lockedPositions = getLockedPositions(
          copiedItems,
          lockedProductSelector,
        );
        const lockedProductIds = new Set(
          lockedPositions.map((pos) => pos.productId),
        );
        const updatedProductSequenceList = copiedItems.filter(
          (item) =>
            !dragItem.items.some(
              (draggedItem) => draggedItem.productId === item.productId,
            ),
        );
        const constSlotsBeforeInsertIndex =
          getContentSlotsBeforeTheCurrentPosition(
            contentSlots,
            insertIndexRef.current.insertIndex,
          );
        // Calculate the actual insertion position in remainingItems
        // This maps the visual drop position to the correct array index
        const insertIndex =
          insertIndexRef.current.insertIndex === -1
            ? 1
            : insertIndexRef.current.insertIndex - constSlotsBeforeInsertIndex;
        const targetIndex = getEffectiveTargetIndex(
          insertIndex,
          copiedItems,
          updatedProductSequenceList,
          lockedProductIds,
        );

        // Get pinned products and positions
        const pinnedIds = new Set([
          ...storedPinnedProductIds,
          ...pinnedProducts,
        ]);
        const originalPositions = new Map(
          copiedItems.map((item, index) => [item.productId, index]),
        );

        // Insert items and get last pinned position
        updatedProductSequenceList.splice(targetIndex, 0, ...dragItem.items);
        const lastPinnedPosition = getLastPinnedPosition(
          updatedProductSequenceList,
          pinnedIds,
          lockedProductIds,
          dragItem.items,
        );

        // Check if either the initial target or final position was locked
        const wasTargetLocked =
          lockedProductIds.has(copiedItems[insertIndex]?.productId) ||
          lockedProductIds.has(copiedItems[targetIndex]?.productId);

        // Only update pinning status if neither position was locked
        if (!wasTargetLocked) {
          dragItem.items.forEach((item) => {
            const shouldBePinned = shouldUpdatePinning(
              item,
              targetIndex,
              originalPositions.get(item.productId) ?? 0,
              lastPinnedPosition,
              updatedProductSequenceList,
              dragItem,
              lockedProductIds,
              pinnedIds,
            );

            if (shouldBePinned !== null) {
              dispatch(
                setPinnedUnPinnedProducts({
                  productId: item.productId,
                  isPinned: shouldBePinned,
                  catalogId,
                  categoryId,
                }),
              );
            }
          });
        }

        // Handle locked positions
        lockedPositions.forEach(({ productId, position }) => {
          const lockedItem = copiedItems.find(
            (item) => item.productId === productId,
          );
          if (lockedItem) {
            const currentIndex = updatedProductSequenceList.findIndex(
              (item) => item.productId === productId,
            );
            if (currentIndex !== -1) {
              updatedProductSequenceList.splice(currentIndex, 1);
            }
            updatedProductSequenceList.splice(position, 0, lockedItem);
          }
        });

        // Dispatch sequence updates
        if (!isFetchedBySearch) {
          dispatch(
            updateProductListInState({
              products: updatedProductSequenceList,
              isSaveAction: true,
            }),
          );
          dispatch(
            updateProductListSequenceMode(
              updatedProductSequenceList.every(
                (e, i) => e.productId === productIds[i],
              )
                ? SEQUENCE_MODE_TYPE.VIEW
                : SEQUENCE_MODE_TYPE.EDIT,
            ),
          );
        } else {
          dispatch(updateProductListBySearch(updatedProductSequenceList));
        }
      }

      setDraggedItemsIds([]);
      setHoveredItemIndex(-1);
      setInsertLineIndex(-1);
    },
    [
      products,
      isFetchedBySearch,
      dispatch,
      lockedProductSelector,
      productIds,
      storedPinnedProductIds,
      pinnedProducts,
      categoryId,
      catalogId,
      contentSlots,
    ],
  );

  const updateProductSequencesByQuickMove = useCallback(
    (productId: string, moveTo: number) => {
      // Get locked products with their one-based positions
      const lockedPositions = products
        .map((product, index) => ({
          product,
          isLocked: lockedProductSelector(product.productId, index + 1),
          position: index,
        }))
        .filter(({ isLocked }) => isLocked);

      // Create initial list without selected and locked products
      let updatedProductList = products.filter(
        (product) =>
          !selectedItems.some(
            (selected) => selected.productId === product.productId,
          ) &&
          !lockedPositions.some(
            (locked) => locked.product.productId === product.productId,
          ),
      );

      const insertIndex = updatedProductList.findIndex(
        (product) => product.productId === productId,
      );
      const insertPosition =
        moveTo === QUICK_MOVE_DIRECTION.RIGHT ? insertIndex + 1 : insertIndex;
      const pinnedProductIds = new Set([
        ...storedPinnedProductIds,
        ...pinnedProducts,
      ]);

      // Filter out locked products from selection
      const unlockedSelectedProducts = selectedItems.filter(
        (selectedProduct) => {
          const productIndex = products.findIndex(
            (p) => p.productId === selectedProduct.productId,
          );
          return !lockedProductSelector(
            selectedProduct.productId,
            productIndex + 1,
          );
        },
      );

      // Store original positions
      const originalPositions = new Map(
        products.map((item, index) => [item.productId, index]),
      );

      // Insert unlocked selected products and update pinning status
      if (insertPosition >= 0 && unlockedSelectedProducts.length > 0) {
        updatedProductList.splice(
          insertPosition,
          0,
          ...unlockedSelectedProducts,
        );

        // Check if products actually moved from their original positions
        const hasProductsMoved = unlockedSelectedProducts?.some((product) => {
          const originalIndex = originalPositions.get(product.productId);
          const newIndex = insertPosition;
          return originalIndex !== newIndex;
        });

        if (hasProductsMoved) {
          // Check for pinned products after insert position (excluding selected products)
          let hasPinnedItemsAfter = false;
          updatedProductList.forEach((product, index) => {
            if (
              index > insertPosition &&
              !selectedItems.some(
                (selected) => selected.productId === product.productId,
              ) &&
              pinnedProductIds.has(product.productId)
            ) {
              hasPinnedItemsAfter = true;
            }
          });

          // Update pinning status for moved products
          unlockedSelectedProducts.forEach((product) => {
            const shouldBePinned = hasPinnedItemsAfter;
            dispatch(
              setPinnedUnPinnedProducts({
                productId: product.productId,
                isPinned: shouldBePinned,
                catalogId,
                categoryId,
              }),
            );
          });
        }
      }

      const updatedProductSequenceList = new Array(products.length);

      // Place locked products in their original positions
      lockedPositions.forEach(({ product, position }) => {
        updatedProductSequenceList[position] = product;
      });

      // Fill remaining positions with unlocked products
      let remainingIndex = 0;
      for (let i = 0; i < updatedProductSequenceList.length; i++) {
        if (
          !updatedProductSequenceList[i] &&
          remainingIndex < updatedProductList.length
        ) {
          updatedProductSequenceList[i] = updatedProductList[remainingIndex];
          remainingIndex++;
        }
      }

      if (!isFetchedBySearch) {
        dispatch(
          updateProductListInState({
            products: updatedProductSequenceList,
            isSaveAction: true,
          }),
        );
        if (
          updatedProductSequenceList.every(
            (e, i) => e.productId === productIds[i],
          )
        ) {
          if (sequenceMode === SEQUENCE_MODE_TYPE.EDIT)
            dispatch(updateProductListSequenceMode(SEQUENCE_MODE_TYPE.VIEW));
        } else {
          if (sequenceMode === SEQUENCE_MODE_TYPE.VIEW)
            dispatch(updateProductListSequenceMode(SEQUENCE_MODE_TYPE.EDIT));
        }
      } else {
        dispatch(updateProductListBySearch(updatedProductSequenceList));
      }
    },
    [
      dispatch,
      isFetchedBySearch,
      selectedItems,
      sequenceMode,
      products,
      lockedProductSelector,
      productIds,
      storedPinnedProductIds,
      pinnedProducts,
      categoryId,
      catalogId,
    ],
  );

  const selectionChangeHandler = useCallback(
    (itemId: string, cmdKeyActive: boolean, shiftKeyActive: boolean) => {
      let newSelectedItemsIds: string[] = [];
      let newActiveItemId;

      // Include both products and content slots in cards
      const cards = [
        ...products,
        ...contentSlots.map((slot) => ({
          ...slot,
          productId: slot.slotId,
        })),
      ];
      let previousSelectedItemsIds = selectedItems
        .map((item) => item.productId || item.slotId)
        .filter((id) => id !== undefined);
      let previousActiveItemId = activeItemId;

      if (cmdKeyActive) {
        if (
          previousSelectedItemsIds.indexOf(itemId) > -1 &&
          itemId !== previousActiveItemId
        ) {
          newSelectedItemsIds = previousSelectedItemsIds.filter(
            (id) => id !== itemId,
          );
        } else {
          newSelectedItemsIds = [...previousSelectedItemsIds, itemId];
        }
      } else if (shiftKeyActive && itemId !== previousActiveItemId) {
        const activeCardIndex = cards.findIndex(
          (c) => c.productId === previousActiveItemId,
        );
        const cardIndex = cards.findIndex((c) => c.productId === itemId);
        const lowerIndex = Math.min(activeCardIndex, cardIndex);
        const upperIndex = Math.max(activeCardIndex, cardIndex);
        newSelectedItemsIds = cards
          .slice(lowerIndex, upperIndex + 1)
          .map((c) => c.productId)
          ?.filter((id) => id !== undefined);
      } else {
        if (previousSelectedItemsIds.indexOf(itemId) > -1) {
          newSelectedItemsIds = [];
          setActiveItemId("");
        } else {
          newSelectedItemsIds = [itemId];
          newActiveItemId = itemId;
        }
      }

      const newSelectedCards = cards.filter((c) =>
        newSelectedItemsIds.includes(c.productId),
      );
      if (newActiveItemId) {
        setActiveItemId(newActiveItemId);
      }
      setSelectedItems(newSelectedCards);
    },
    [activeItemId, products, selectedItems, contentSlots],
  );

  const keyPressHandler = useCallback(
    (e: KeyboardEvent) => {
      const isCopyShortcutPressed = e.metaKey || e.ctrlKey;
      const isCopyKeyC = e.code === "KeyC";
      const isInputOrTextareaActive =
        document.activeElement instanceof HTMLInputElement ||
        document.activeElement instanceof HTMLTextAreaElement;
      const condition =
        selectedItems.length > 0 &&
        isCopyShortcutPressed &&
        !isInputOrTextareaActive &&
        isCopyKeyC;
      if (condition) {
        const payload = {
          productCodes: selectedItems.map(
            (item) => productById[item.productId]?.code,
          ),
        };
        addClipboardProductCodes(payload);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, selectedItems],
  );
  const badgesList = useSelector(selectProductBadgeList);
  const currentStoreId = useSelector(selectCurrentStoreId);
  const filteredBadgesList = useMemo(
    () =>
      badgesList?.filter(
        (badge) => badge.storeId === currentStoreId || badge.storeId === null,
      ) ?? [],
    [badgesList, currentStoreId],
  );

  useEffect(() => {
    document.addEventListener("keydown", keyPressHandler);
    return () => {
      document.removeEventListener("keydown", keyPressHandler);
    };
  }, [keyPressHandler]);

  useEffect(() => {
    if (selectedOverlay !== OVERLAY_TABS.ANALYTICS_OVERLAY)
      dispatch(resetAllProducAnalyticsView());
  }, [dispatch, selectedOverlay]);

  useEffect(() => {
    if (
      selectedOverlay === OVERLAY_TABS.PRODUCTS_BADGES &&
      filteredBadgesList.length === 0 &&
      categoryPathQuery
    ) {
      dispatch(badgeConfigureMessage());
    }
  }, [selectedOverlay, dispatch, filteredBadgesList, categoryPathQuery]);

  const containerWidth = width ?? 0;
  const cwidth = containerWidth + 15;
  const containerHeight = height ?? 0;

  // Update rowCount calculation to include both products and content slots
  const totalItems = newSequence?.length ?? 0;
  const rowCount = Math.ceil(totalItems / viewContext.styles.column);
  const gridRef = useRef(null);
  useDndScrolling(gridRef, {});

  return (
    <>
      {!isStoresLoaded ||
      isCatalogsLoading ||
      isTopCategoriesLoading ||
      isFetchingProductIds ||
      isContentSlotLoading ? (
        <StyledDiv className={styleClasses.overlay}>
          <CircularProgress />
        </StyledDiv>
      ) : null}
      <StyledDiv
        className={styleClasses.productList}
        ref={ref}
        fontSize={viewContext.styles.productListFZ}
      >
        {newSequence && Array.isArray(newSequence) && (
          <FixedSizeGrid
            outerRef={gridRef}
            columnCount={viewContext.styles.column}
            columnWidth={containerWidth / viewContext.styles.column}
            rowCount={rowCount}
            rowHeight={
              PRODUCT_TILE_HEIGHTS.get(viewContext.styles.column) ?? 400
            }
            width={cwidth}
            height={containerHeight}
            itemData={{
              draggedItemsIds,
              newSequence,
              selectedItems,
              onSelectionChange: selectionChangeHandler,
              onItemDragStart: itemDragStartHandler,
              onMove: itemMoveHandler,
              onDragComplete: dragCompleteHandler,
              OnQuickMoveCompleteAction: updateProductSequencesByQuickMove,
              hoveredItemIndex,
              insertLineIndex,
              insertIndexRef,
            }}
            itemKey={({ columnIndex, data, rowIndex }) => {
              const index = rowIndex * viewContext.styles.column + columnIndex;
              const item = data.newSequence[index] ?? {};
              return `${item.productId || item.slotId}_${index}`;
            }}
            style={{ overflowY: "auto", overflowX: "hidden" }}
          >
            {ProductTileWrapper}
          </FixedSizeGrid>
        )}
      </StyledDiv>
    </>
  );
};

export default React.memo(ProductList);
