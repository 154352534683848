import type { ThemeOptions } from "@mui/material";

interface INeutral {
  black: string;
  white: string;
  grey: string;
  darkGrey: string;
  darkGrey2: string;
  ashGrey: string;
  lightGrey: string;
  blue: string;
  yellow: string;
  darkGreyish: string;
  boxShadow: string;
  brightGray: string;
  secondaryDark: string;
  blueColorDark: string;
  darkCharcoal: string;
  tableBorderColor: string;
  shakeSpeareColor: string;
  neutralGrey: string;
}

declare module "@mui/material/styles/createPalette" {
  interface PaletteOptions {
    borderColor?: PaletteOptions["primary"];
    focusColor?: PaletteOptions["primary"];
    dangerColor?: PaletteOptions["primary"];
    primaryHoverColor?: {
      main: string;
      hoverLight: string;
    };
    secondaryButtonColorCTABlue?: {
      main: string;
    };
    unifiedSortingRuleModalMenuItem?: {
      main: string;
    };
    unSelectTextColor?: {
      main: string;
    };
    sideBarTitle?: {
      main: string;
    };
    selectedMenuItem?: {
      main: string;
    };
    hoveredMenuItem?: {
      main: string;
    };
    tableHeaderRow?: {
      main: string;
      contrastText: string;
    };
    tableRowHover?: {
      main: string;
      contrastText: string;
    };
    tertiary?: {
      main: string;
      hoverColor: string;
      contrastText: string;
    };
    neutral?: INeutral;
  }

  interface Palette {
    borderColor: Palette["primary"];
    focusColor: Palette["primary"];
    dangerColor: Palette["primary"];
    primaryHoverColor?: {
      main: string;
      hoverLight: string;
    };
    secondaryButtonColorCTABlue: {
      main: string;
    };
    unifiedSortingRuleModalMenuItem: {
      main: string;
    };
    unSelectTextColor: {
      main: string;
    };
    sideBarTitle: {
      main: string;
    };
    selectedMenuItem: {
      main: string;
    };
    hoveredMenuItem: {
      main: string;
    };
    tableHeaderRow: {
      main: string;
      contrastText: string;
    };
    tableRowHover: {
      main: string;
      contrastText: string;
    };
    tertiary: {
      main: string;
      hoverColor: string;
      contrastText: string;
    };
    neutral: INeutral;
  }
}

const WHITE = "#ffffff";

export const PRIMARY_COLOR = "#F18221";
export const PRIMARY_DARK = "#E84C25";
export const PRIMARY_HOVER = "#FFD9CB";

export const SECONDARY_COLOR = "#68717E";
export const SECONDARY_DARK = "#828282";

export const TERTIARY_COLOR = "#78A6BE";
export const TERTIARY_DARK = "#5A7D8F";

export const BACKGROUND_COLOR = "#ffffff";

export const TEXT_PRIMARY_COLOR = "#000000";
export const TEXT_SECONDARY_COLOR = "#555555";
export const SIDE_BAR_TITLE_COLOR = "#404041";
export const TABLE_HEADER_ROW = "#f18308";
export const TABLE_ROW_HOVER = "#fedfc3";

export const BORDER_COLOR = "#BDBDBD";
export const FOCUS_COLOR = "#3F51B5";
export const HOVER_COLOR = "#D50000";
export const UNSELECT_TEXT_COLOR = "#0C61A0";
export const DANGER_COLOR = "#B82A1D";
export const ERROR_COLOR = "#DC3545";

export const BLACK = "#000000";
export const WHITE_COLOR = "#FFFFFF";
export const GREY = "#79797A";
export const DARK_GREY = "#68717E";
export const DARK_GREY2 = "#828282";
export const ASH_GREY = "#A6A6A6";
export const LIGHT_GREY = "#F2F3F4";
export const BLUE = "#3F51B5";
export const YELLOW_COLOR = "#FFD700";
export const DARK_GREYISH = "#4D4D4E";
export const BOX_SHADOW = "#B473FF";
export const BRIGHT_GRAY = "#EEEEEE";

export const HOVERED_MENU_ITEM = "#F8C090";
export const SELECTED_MENU_ITEM = "#F18221";
export const RED = "#ED2929";
export const DARK_BLUE_COLOR = "#0000FF";
export const DARK_CHARCOAL = "#333333";
export const TABLE_BORDER_COLOR = "#C6C6C6";
export const SHAKE_SPEARE_COLOR = "#599DBB";
export const NEUTRAL_GREY = "#DCDCDC";

export const NEUTRAL = {
  black: BLACK,
  white: WHITE_COLOR,
  grey: GREY,
  darkGrey: DARK_GREY,
  darkGrey2: DARK_GREY2,
  ashGrey: ASH_GREY,
  lightGrey: LIGHT_GREY,
  blue: BLUE,
  yellow: YELLOW_COLOR,
  darkGreyish: DARK_GREYISH,
  boxShadow: BOX_SHADOW,
  brightGray: BRIGHT_GRAY,
  secondaryDark: SECONDARY_DARK,
  blueColorDark: DARK_BLUE_COLOR,
  darkCharcoal: DARK_CHARCOAL,
  tableBorderColor: TABLE_BORDER_COLOR,
  shakeSpeareColor: SHAKE_SPEARE_COLOR,
  neutralGrey: NEUTRAL_GREY,
};

export const DEFAULT_CONTRAST_TEXT = WHITE;

export const palette: ThemeOptions["palette"] = {
  primary: {
    main: PRIMARY_COLOR,
    contrastText: DEFAULT_CONTRAST_TEXT,
  },
  secondary: {
    main: SECONDARY_COLOR,
    contrastText: DEFAULT_CONTRAST_TEXT,
  },
  background: {
    default: BACKGROUND_COLOR,
  },
  tertiary: {
    main: TERTIARY_COLOR,
    hoverColor: TERTIARY_DARK,
    contrastText: DEFAULT_CONTRAST_TEXT,
  },
  primaryHoverColor: { main: PRIMARY_DARK, hoverLight: PRIMARY_HOVER },
  secondaryButtonColorCTABlue: { main: FOCUS_COLOR },
  sideBarTitle: { main: SIDE_BAR_TITLE_COLOR },
  selectedMenuItem: { main: SELECTED_MENU_ITEM },
  unifiedSortingRuleModalMenuItem: { main: HOVER_COLOR },
  unSelectTextColor: { main: UNSELECT_TEXT_COLOR },
  hoveredMenuItem: { main: HOVERED_MENU_ITEM },
  tableHeaderRow: {
    main: TABLE_HEADER_ROW,
    contrastText: "#fff",
  },
  tableRowHover: {
    main: TABLE_ROW_HOVER,
    contrastText: "#fff",
  },
  error: {
    main: ERROR_COLOR,
  },
  text: {
    primary: TEXT_PRIMARY_COLOR,
    secondary: TEXT_SECONDARY_COLOR,
  },
  borderColor: {
    main: BORDER_COLOR,
  },
  focusColor: {
    main: FOCUS_COLOR,
  },
  dangerColor: {
    main: `${DANGER_COLOR} !important`,
  },
  neutral: NEUTRAL,
};
