import {
  FETCH_CONFIG_REQUEST,
  UPDATE_CONFIG_REQUEST,
  CREATE_PRECACHING_JOB_REQUEST,
  PrecachingCrawlerConfig,
} from "./PrecachingCrawlerTypes";

export const fetchPrecachingCrawlerConfig = (accountId: string) => ({
  type: FETCH_CONFIG_REQUEST,
  payload: accountId,
});

export const updatePrecachingCrawlerConfig = (
  config: PrecachingCrawlerConfig,
) => ({
  type: UPDATE_CONFIG_REQUEST,
  payload: config,
});

export const createPrecachingJob = (
  accountId: string,
  dataTypes: string[],
) => ({
  type: CREATE_PRECACHING_JOB_REQUEST,
  payload: { accountId, dataTypes },
});
