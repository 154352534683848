import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Typography } from "@mui/material";
import AppState from "../../store/AppState";
import { selectIsModalOpen } from "../../store/modal/ModalSelectors";
import { setModalState } from "../../store/modal/ModalActions";
import { selectIsProductDeleted } from "../../store/remove-product-categories/RemoveProductCategoriesSelector";
import {
  DeleteProductByCategories,
  ResetProductDeletedState,
} from "../../store/remove-product-categories/RemoveProductCategoriesActions";
import {
  selectLockedProducts,
  selectProductListsFetched,
} from "../../store/product-list/ProductListSelectors";
import {
  selectChildCategories,
  selectCurrentCategory,
  selectTopCategories,
} from "../../store/category/CategorySelectors";
import {
  AddProductsToCategory,
  ResetProductAddedState,
} from "../../store/add-product-categories/AddProductCategoriesActions";
import {
  selectCurrentLocale,
  selectCurrentStoreId,
} from "../../store/store-list/StoreListSelectors";
import ButtonComponent from "../common/ButtonComponent";
import { selectCurrentCatalogId } from "../../store/catalog/CatalogSelectors";
import { useIntl } from "react-intl";
import { selectIsProductAdded } from "../../store/add-product-categories/AddProductCategoriesSelector";
import { ProductSequenceData } from "../../store/product-list/ProductListTypes";
import { CircularProgress } from "@material-ui/core";
import CustomDialog from "../common/DialogContainer";
import {
  styleClasses,
  StyledDiv,
} from "../remove-products-modal/RemoveProductsModalStyles";
import { selectValidProductIds } from "store/clipboard/ClipBoardSelectors";
import { filterLockedProducts } from "utils/ProductUtil";

const ReplaceProductsModal = (props) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [progress, setProgress] = useState(false);
  const isProductDeleted = useSelector(selectIsProductDeleted);
  const isProductAdded = useSelector(selectIsProductAdded);
  const currentCategoryId = useSelector(selectCurrentCategory);
  const currentStoreId = useSelector(selectCurrentStoreId) ?? "";
  const currentLocaleCode = useSelector(selectCurrentLocale) ?? "";
  const currentCatalogId = useSelector(selectCurrentCatalogId) ?? "";
  const childCategories = useSelector(selectChildCategories);
  const topCategories = useSelector(selectTopCategories);
  const productsList = useSelector(selectProductListsFetched);
  const addProductIds = useSelector(selectValidProductIds);
  const lockedProductsInCategory = useSelector((state: AppState) =>
    selectLockedProducts(state, currentCategoryId, currentCatalogId),
  );
  const isModalOpen = useSelector((state: AppState) =>
    selectIsModalOpen(state, "ReplaceProductsModal"),
  );
  const [deleteProducts, setDeleteProducts] = useState<ProductSequenceData[]>(
    [],
  );
  const [addProducts, setAddProducts] = useState<string[]>([]);

  useEffect(() => {
    if (addProductIds) {
      const productsTobeDeleted = productsList.filter(
        (prod) => !addProductIds.includes(prod.productId),
      );
      setDeleteProducts(productsTobeDeleted);
      const idsMap = {};
      productsList.forEach((prod) => (idsMap[prod.productId] = true));
      const productsTobeAdded = addProductIds.filter((id) => !idsMap[id]);
      setAddProducts(productsTobeAdded);
    }
  }, [productsList, addProductIds]);

  const handleClose = () => {
    setProgress(false);
    dispatch(setModalState("ReplaceProductsModal", false));
  };

  useEffect(() => {
    if (isProductAdded && !deleteProducts.length) {
      handleClose();
      dispatch(ResetProductAddedState());
    } else if (isProductDeleted) {
      handleClose();
      dispatch(ResetProductDeletedState());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProductDeleted, isProductAdded]);

  const handleReplace = () => {
    if (addProducts.length === 0 && deleteProducts.length === 0) {
      handleClose();
      return;
    }
    const tcFilter = topCategories.filter((e) =>
      currentCategoryId.includes(e.categoryId),
    );
    const ccFilter = Object.entries(childCategories)
      .map((e) => {
        const search = e[1].childCategories.find((el) =>
          currentCategoryId.includes(el.categoryId),
        );
        if (search) {
          return search.categoryId;
        }
        return null;
      })
      .filter((e) => e !== null);

    if (addProducts.length) {
      let categoryIds: {
        storeId: string;
        catalogId: string;
        categoryId?: string;
      }[] = [];
      topCategories.find((e) => e.categoryId === currentCategoryId)
        ? categoryIds.push({
            storeId: currentStoreId,
            catalogId: currentCatalogId,
          })
        : categoryIds.push({
            storeId: currentStoreId,
            catalogId: currentCatalogId,
            categoryId: currentCategoryId,
          });
      dispatch(
        AddProductsToCategory({
          products: addProducts,
          categoryId: currentCategoryId,
          catalogId: currentCatalogId,
          storeId: currentStoreId,
          localeCode: currentLocaleCode,
          categoryIds: categoryIds,
        }),
      );
    }
    if (deleteProducts) {
      const products: string[] = [];
      const categories: string[] = [currentCategoryId];
      deleteProducts.forEach((prod) => {
        products.push(prod.productId);
      });
      const lockedProducts = filterLockedProducts(
        lockedProductsInCategory,
        products,
      );
      dispatch(
        DeleteProductByCategories({
          productIds: products,
          categoryIds: categories,
          childrenCategories: ccFilter,
          topCategories: tcFilter,
          lockedProducts: lockedProducts,
        }),
      );
    }
    setProgress(true);
  };

  const replaceProductsModalTitle = () => {
    return (
      <Typography variant="inherit">
        {intl.formatMessage({
          id: "replaceProductsModal.modalTitle",
          defaultMessage: "Replace Products",
        })}
      </Typography>
    );
  };

  const replaceProductsModalContent = () => {
    return (
      <StyledDiv className={styleClasses.message}>
        <Typography variant="subtitle1">
          {intl.formatMessage({
            id: "replaceProductsModal.instruction",
            defaultMessage:
              "Are you sure you want to proceed to replace products in this category with the ones in the clipboard ?",
          })}
        </Typography>
      </StyledDiv>
    );
  };

  const dialogActions = () => {
    return (
      <StyledDiv className={styleClasses.actiondiv}>
        {progress && (
          <StyledDiv className={styleClasses.overlay}>
            <CircularProgress />
          </StyledDiv>
        )}
        <ButtonComponent
          color="secondaryButtonColorCTABlue"
          variant="outlined"
          onClick={handleClose}
        >
          {intl.formatMessage({
            id: "general.cancel",
            defaultMessage: "Cancel",
          })}
        </ButtonComponent>
        <ButtonComponent
          color="primary"
          variant="contained"
          onClick={handleReplace}
        >
          {intl.formatMessage({
            id: "general.Proceed",
            defaultMessage: "Proceed",
          })}
        </ButtonComponent>
      </StyledDiv>
    );
  };

  return (
    <CustomDialog
      open={isModalOpen}
      onClose={handleClose}
      title={replaceProductsModalTitle()}
      fullWidth
      maxWidth={"md"}
      actions={dialogActions()}
    >
      {replaceProductsModalContent()}
    </CustomDialog>
  );
};

export default ReplaceProductsModal;
