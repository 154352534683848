import React, { useEffect } from "react";
import { CircularProgress, useTheme } from "@mui/material";
import Typography from "@material-ui/core/Typography";
import { useIntl } from "react-intl";
import CustomDialog from "../common/DialogContainer";
import ButtonComponent from "../common/ButtonComponent";
import useStyles from "./VariationReassignmentStyles";
import { getProductName } from "utils/ProductUtil";
import { useDispatch, useSelector } from "react-redux";
import { selectConfigValue } from "store/app-config/AppConfigSelectors";
import AppState from "store/AppState";
import {
  selectCurrencyCode,
  selectCurrentLocale,
  selectCurrentStoreId,
} from "store/store-list/StoreListSelectors";
import {
  selectIsReassignVariationsLoading,
  selectIsReassignVariationsCompleted,
  selectIsVariantFetched,
  selectProductFetched,
  selectProductVariants,
} from "store/product/ProductSelectors";
import {
  selectIsModalOpen,
  selectModalVariable,
} from "store/modal/ModalSelectors";
import { setModalState } from "store/modal/ModalActions";
import { CloseModal } from "store/modal/ModalActions";
import VariationReassignmentList from "./VariationReassignmentList";
import { StyledDiv, modalClasses } from "./VariationReassignmentStyles";
import {
  FetchProductsVariants,
  ResetProductVariantsFetchedState,
  resetReassignVariationsCompleted,
} from "store/product/ProductActions";
import { reassignVariations } from "store/product/ProductActions";
import TextFieldComponent from "components/common/TextField";
import { CURRENCY_MAP } from "utils/Constants";

export const VariationReassignmentModalId = "VariationReassignmentModal";

export default function VariationReassignmentModal() {
  const theme = useTheme();
  const classes = useStyles(theme);
  const intl = useIntl();
  const dispatch = useDispatch();
  const [selectedVariations, setSelectedVariations] = React.useState<string[]>(
    [],
  );
  const [targetProductId, setTargetProductId] = React.useState("");
  const productId = useSelector((state: AppState) =>
    selectModalVariable(state, VariationReassignmentModalId, "productId"),
  );
  const open = useSelector((state: AppState) =>
    selectIsModalOpen(state, VariationReassignmentModalId),
  );
  const fetchedProduct = useSelector((state: AppState) =>
    selectProductFetched(state, productId),
  );
  const localeId = useSelector(selectCurrentLocale) || "";
  const defaultLocaleId = useSelector((state: AppState) =>
    selectConfigValue(state, "defaultLocaleId"),
  );
  const storeId = useSelector(selectCurrentStoreId) ?? "";
  const isVariantsFetched = useSelector((state: AppState) =>
    selectIsVariantFetched(state),
  );
  const itemsData = useSelector(selectProductVariants);
  const isReassignVariationsLoading = useSelector(
    selectIsReassignVariationsLoading,
  );
  const isReassignVariationsCompleted = useSelector(
    selectIsReassignVariationsCompleted,
  );
  const currencyCode = useSelector(selectCurrencyCode);
  let currencySymbol = "$";
  if (CURRENCY_MAP[currencyCode]) {
    currencySymbol = CURRENCY_MAP[currencyCode];
  }

  const handleClose = () => {
    dispatch(setModalState(VariationReassignmentModalId, false));
    dispatch(CloseModal({ productId: productId }));
    setTargetProductId("");
    setSelectedVariations([]);
  };

  useEffect(() => {
    if (open) {
      const payload = {
        productId: productId,
        storeId: storeId,
        localeCode: localeId,
      };
      dispatch(FetchProductsVariants(payload));
    } else {
      dispatch(ResetProductVariantsFetchedState());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (isReassignVariationsCompleted) {
      setSelectedVariations([]);
      dispatch(resetReassignVariationsCompleted());
    }
  }, [isReassignVariationsCompleted, dispatch]);

  const handleCheck = (
    event: React.MouseEvent<unknown>,
    SKU: string,
    key: string,
  ) => {
    setSelectedVariations((prev) => {
      const isCurrentlySelected = prev.includes(SKU);
      if (isCurrentlySelected) {
        return prev.filter((item) => item !== SKU);
      }
      return [...prev, SKU];
    });
  };

  const handleReassign = () => {
    if (selectedVariations.length > 0 && targetProductId) {
      const childProductIds = itemsData?.data
        ?.filter((prod) => selectedVariations.includes(prod.code))
        ?.map((prod) => prod.variantId);
      dispatch(
        reassignVariations({
          productId: targetProductId,
          childProductIds: childProductIds,
          sourceProductId: productId,
        }),
      );
    }
  };

  const variationReassignmentModalTitle = () => {
    return (
      <Typography variant="inherit">
        {intl.formatMessage({
          id: "variationReassignmentModal.title",
          defaultMessage: "Reassign",
        })}{" "}
        {fetchedProduct
          ? ` - ${getProductName(fetchedProduct, localeId, defaultLocaleId)}`
          : ""}
      </Typography>
    );
  };

  const dialogActions = () => {
    return (
      <div style={classes.dialogAction}>
        <div style={classes.targetStyle}>
          <TextFieldComponent
            textFieldId="variationReassignmentModal.targetProductId"
            required
            variant="standard"
            placeholderText={intl.formatMessage({
              id: "variationReassignmentModal.targetProductIdLabel",
              defaultMessage: "Enter Target Product ID",
            })}
            value={targetProductId}
            onChange={(e) => setTargetProductId(e.target.value)}
            size="small"
          />
        </div>
        <div style={classes.actionButtons}>
          <ButtonComponent
            color="secondaryButtonColorCTABlue"
            variant="outlined"
            onClick={handleClose}
            style={classes.cancelButton}
          >
            {intl.formatMessage({
              id: "variationReassignmentModal.cancel",
              defaultMessage: "CANCEL",
            })}
          </ButtonComponent>
          <ButtonComponent
            color="primary"
            variant="contained"
            onClick={handleReassign}
            disabled={selectedVariations.length === 0 || !targetProductId}
          >
            {intl.formatMessage({
              id: "variationReassignmentModal.reassign",
              defaultMessage: "REASSIGN",
            })}
          </ButtonComponent>
        </div>
      </div>
    );
  };

  const variationReassignmentModalContent = () => {
    return (
      <StyledDiv className={modalClasses.divTable}>
        {!isVariantsFetched ? (
          <StyledDiv className={modalClasses.dialogChildClass}>
            <CircularProgress color="secondary" />
          </StyledDiv>
        ) : (
          <>
            {isReassignVariationsLoading && (
              <StyledDiv className={modalClasses.dialogChildClass}>
                <CircularProgress color="secondary" />
              </StyledDiv>
            )}
            <VariationReassignmentList
              items={itemsData.data.map((prod) => {
                const attributeCodes = {};
                prod?.variationOptions?.forEach((option) => {
                  attributeCodes[option?.attributeLabel] = option?.valueLabel;
                });
                return {
                  SKU: prod.code,
                  ...attributeCodes,
                  Inventory: prod.stock,
                  Price: prod.price ? `${currencySymbol}${prod.price}` : "",
                };
              })}
              handleCheck={handleCheck}
              selectedRows={selectedVariations}
            />
          </>
        )}
      </StyledDiv>
    );
  };

  return (
    <CustomDialog
      open={open}
      onClose={handleClose}
      title={variationReassignmentModalTitle()}
      actions={
        !isVariantsFetched || !itemsData?.data?.length ? null : dialogActions()
      }
      maxWidth="lg"
      fullWidth
    >
      {variationReassignmentModalContent()}
    </CustomDialog>
  );
}
